import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ThemeHookProvider } from '../../hooks/theme.hooks';
import store from '../../data/store';
import { defaultTheme } from '../../styles/vendorThemes/default.theme';
import { getVendorTheme } from '../../styles/vendorThemes/vendorTheme';
import ErrorBoundary from './utility/ErrorBoundary';

AppProviderWrapper.propTypes = {
  themeVendor: PropTypes.object,
};

AppProviderWrapper.defaultProps = {
  themeVendor: null,
};

export function AppProviderWrapper(props) {
  const { themeVendor } = props;
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    if (themeVendor) {
      setTheme(getVendorTheme(themeVendor));
    } else {
      setTheme(defaultTheme);
    }
  }, [themeVendor]);

  return (
    <MuiThemeProvider muiTheme={theme}>
      <ThemeHookProvider>
        <ErrorBoundary>
          <Provider store={store}>
            {props.children}
          </Provider>
        </ErrorBoundary>
      </ThemeHookProvider>
    </MuiThemeProvider>
  );
}
