import qs from 'qs';
import { fleatAPI } from '../api.service';

class ProfilesAPI {
  constructor() {
    this.ROLE = {
      POTENTIAL: 'POTENTIAL',
      CUSTOMER: 'CUSTOMER',
    };

    this.MESSAGE_FREQUENCY = {
      ANY: 'ANY',
      WEEKLY: 'WEEKLY',
      MONTHLY: 'MONTHLY',
    };
  }

  async getProfileByParams(params) {
    const response = await fleatAPI.callAPI({
      url: `/profiles?${qs.stringify(params)}`,
      method: 'GET',
    });
    return response;
  }

  async getDepotProfileByParams(depotID, params) {
    const response = await fleatAPI.callAPI({
      url: `/profiles/depot/${depotID}?${qs.stringify(params)}`,
      method: 'GET',
    });
    return response;
  }

  async getProfileByID(profileID) {
    const response = await fleatAPI.callAPI({
      url: `/profiles/${profileID}`,
      method: 'GET',
    });
    return response;
  }

  /**
   * This will fetch the currently logged in user's customer profile for the given vendor
   *
   * @param vendorID - the id of the vendor the profile should be associated with
   * @returns {Promise<Object>} - customer profile data
   */
  async getProfileByVendorID(vendorID) {
    return fleatAPI.callAPI({
      url: `/profiles/me/${vendorID}`,
    });
  }

  async update(profileID, data) {
    if (!data) {
      throw new Error('No profile data was provided!');
    }

    return fleatAPI.callAPI({
      method: `put`,
      url: `/profiles/${profileID}`,
      data,
    });
  }

  async createPotentialProfile(data) {
    if (!data) {
      throw new Error('No profile data was provided!');
    }

    return fleatAPI.callAPI({
      method: `post`,
      url: `/profiles`,
      data,
    });
  }

  /**
   * Updates a user's position field on their profile record
   *
   * @param profileID
   * @param address
   * @returns {Promise<void>}
   */
  async updateCustomerDeliveryAddress(profileID, address) {
    return fleatAPI.callAPI({
      method: `patch`,
      url: `/profiles/${profileID}/delivery-address`,
      data: address,
    });
  }
}

export const profilesAPI = new ProfilesAPI();
