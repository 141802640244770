import { vendorsAPI } from '../../services/api/vendors.apiService';
import { shiftsAPI } from '../../services/api/shifts.apiService';
import Messages from '../../services/models/flashNotifications.model';
import { profileSelector } from '../selectors/user.selector';
import * as types from './types';

function storeConnectedVendor(data) {
  return {
    type: types.SET_CONNECTED_VENDOR,
    data,
  };
}

export function fetchVendor(vendorID) {
  return async (dispatch) => {
    dispatch({ type: types.LOADING_CONNECTED_VENDOR, loading: true });
    const vendor = await vendorsAPI.getVendorByID(vendorID);
    dispatch(storeConnectedVendor(vendor));
    dispatch({ type: types.LOADING_CONNECTED_VENDOR, loading: false });
  };
}

export function fetchVendorMessages() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.LOADING_FLASH_MESSAGES, loading: true });
      const profile = profileSelector(getState());
      const messages = await Messages.getFlashMessages(profile.vendorID);
      dispatch({ type: types.SET_FLASH_MESSAGES, data: messages });
      dispatch({ type: types.LOADING_FLASH_MESSAGES, loading: false });
    } catch (error) {
      console.warn('fetchVendorMessages error', error);
      dispatch({ type: types.LOADING_FLASH_MESSAGES, loading: false });
    }
  };
}
