import * as types from '../../actions/types';

const initialState = null;

export default function estimatedDeliveryTime(state = initialState, action) {
  switch (action.type) {
    case types.SET_ESTIMATED_DELIVERY_TIME:
      return action.data;
    default:
      return state;
  }
}
