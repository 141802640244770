import _get from 'lodash/get';

export const getIsAuthFinished = (state) => {
  return !!_get(state, 'initialized.auth', false);
};

export const getIsAppInitFinished = (state) => {
  return !!_get(state, 'initialized.app', false);
};

export const getIsAppOnline = (state) => {
  return !!_get(state, 'config.ready', false);
};
