import React, { createContext, useContext } from 'react';
import { withRouter } from 'react-router-dom';

export const RouterContext = createContext({});

function Provider(props) {
  const { history, match, location } = props;
  const value = { history, match, location };
  return (
    <RouterContext.Provider value={value}>
      {props.children}
    </RouterContext.Provider>
  );
}

export const RouterHookProvider = withRouter(Provider);

export function useRouter() {
  return useContext(RouterContext);
}

export function useRouterHistory() {
  const { history } = useRouter();
  return history;
}

export function useRouterParams() {
  const { match } = useRouter();
  return match.params;
}

export function useRouterLocation() {
  const { location } = useRouter();
  return location;
}
