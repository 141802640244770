import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { profileSelector } from '../user.selector';
import { nearbyLoadingSelector } from './nearby.selector';

export const deliveryAddressLoadingSelector = (state) => {
  return _get(state, 'customer.deliveryAddress.loading', false);
};

export const deliveryAddressSelector = (state) => {
  return _get(state, 'customer.deliveryAddress.data', null);
};

export const deliveryAddressAndNearbyLoadingSelector = createSelector(
  deliveryAddressLoadingSelector,
  nearbyLoadingSelector,
  (deliveryAddressLoading, nearbyLoading) => {
    return deliveryAddressLoading || nearbyLoading;
  },
);

export const savedAddressesSelector = createSelector(
  profileSelector,
  (profile) => {
    return _get(profile, 'addresses', []);
  },
);

export const lastDeliveryAddressSelector = createSelector(
  profileSelector,
  savedAddressesSelector,
  (profile, addresses) => {
    return addresses.find(a => a.isDeliveryAddress);
  },
);

export const defaultDeliveryAddressSelector = createSelector(
  profileSelector,
  savedAddressesSelector,
  (profile, addresses) => {
    return addresses.find(a => a.isDeliveryAddress);
  },
);
