import { combineReducers } from 'redux';
import * as types from '../../actions/types';

const initialState = {
  // loading: false,
  data: null,
};

function data(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_CONNECTED_SHIFT:
      return action.data;
    case types.SET_CONNECTED_VEHICLE:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  // loading,
  data,
});
