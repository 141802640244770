import { profilesAPI } from '../../services/api/profiles.apiService';
import { connectedVendorSelector } from '../selectors/customer/connectedVendor.selectors';
import { composedUserSelector, profileSelector } from '../selectors/user.selector';
import * as types from './types';

/**
 * [Action Creator] for storing the firebase auth user
 *
 * @param {object} data - User object
 * @returns {{type, data: *}}
 */
export function storeAuthUser(data) {
  return {
    type: types.SET_AUTH_USER,
    data,
  };
}

/**
 * [Action Creator] for storing Profile
 *
 * @param {object} data - Profile object
 * @returns {{type, data: *}}
 */
export function storeProfile(data) {
  return {
    type: types.SET_PROFILE_DATA,
    data,
  };
}

/**
 * [Async Action] Fetches the current user's Profile from the database
 */
export function fetchProfile() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const user = composedUserSelector(getState());
      const vendor = connectedVendorSelector(getState());
      if (user) {
        profilesAPI.getProfileByVendorID(vendor.id)
          .then((profile) => {
            dispatch(storeProfile(profile));
            resolve(profile);
          })
          .catch((error) => {
            console.warn('fetchProfile error', error);
            reject(error);
          });
      }
      return null;
    });
  };
}

/**
 * [Async Action] Saves Profile to the database
 *
 * @param {object} data - Profile object
 */
export function saveProfile(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.LOADING_USER_PROFILE, loading: true });
      const currentProfile = profileSelector(getState());
      const profile = await profilesAPI.update(currentProfile.id, data);
      dispatch(storeProfile(profile));
      dispatch({ type: types.LOADING_USER_PROFILE, loading: false });
      return profile;
    } catch (error) {
      console.warn('saveProfile error', error);
      dispatch({ type: types.LOADING_USER_PROFILE, loading: false });
      throw error;
    }
  };
}


/**
 * [Async Action] Saves any missing Profile information to the database
 *
 * @param {object} formData - Profile object
 */
export function saveProfileFieldsIfNotExisting(formData) {
  return async (dispatch, getState) => {
    const profileDataInState = profileSelector(getState());

    let customerData = null;

    if (!profileDataInState.phoneNumber) {
      customerData = Object.assign({}, customerData, { phoneNumber: formData.phoneNumber });
    }

    if (!profileDataInState.displayName) {
      customerData = Object.assign({}, customerData, { displayName: formData.displayName });
    }

    if (!profileDataInState.email) {
      customerData = Object.assign({}, customerData, { email: formData.email });
    }

    if (customerData) {
      return profilesAPI.update(profileDataInState.id, customerData)
        .then((res) => {
          dispatch(fetchProfile());
          return res;
        })
        .catch((error) => {
          console.warn('saveProfileFieldsIfNotExisting error', error);
        });
    }
  };
}
