import moment from 'moment';
import { fleatAPI } from '../api.service';
import firebase from '../firebase.service';
import FirestoreSanitizer from './FirestoreSanitizer';

/**
 * Order Notifications sent FROM a driver (or “vehicle”, if automated) TO customers
 */
class OrderNotificationsModel {
  constructor() {
    this.sanitize = new FirestoreSanitizer(this.COLLECTION_PROPS);
  }

  // ***
  // Module Constants
  // ***

  /**
   * Firestore collection name
   *
   * @type {string}
   */
  COLLECTION_NAME = 'orderNotifications';

  /**
   * List of available properties for documents in this collections
   *
   * @type {string[]}
   */
  COLLECTION_PROPS = [
    'orderID',
    'shiftID',
    'sentAt',
    'content',
    'vehicleID',
    'senderID',
    'type',
    'twilioResult',
  ];

  TYPES = {
    NEW_ORDER: 'NEW_ORDER',
    RECEIPT_EMAIL: 'RECEIPT_EMAIL',
    DELIVERED_EMAIL: 'DELIVERED_EMAIL',
    CANCELLED_EMAIL: 'CANCELLED_EMAIL',
    PREORDER_REMINDER: 'PREORDER_REMINDER',
    PICKUP_READY: 'PICKUP_READY',
    ON_THE_WAY: 'ON_THE_WAY',
    ARRIVING_SOON: 'ARRIVING_SOON',
    HERE: 'HERE',
    DOORSTEP: 'DOORSTEP',
    DELIVERED: 'DELIVERED',
    RE_OPENED: 'RE_OPENED',
  };

  subscriptions = {};

  collectionRef = firebase.firestore().collection(this.COLLECTION_NAME);

  // ***
  // Module Exported Functions
  // ***

  /**
   * Returns an array of orderNotification objects
   *
   * @param orderID - The ID of the order to fetch notifications for
   * @returns {Promise<*>}
   */
  getOrderNotifications(orderID) {
    return fleatAPI.callAPI({
      url: `orders/${orderID}/notifications`,
      method: `GET`,
    });
  }

  /**
   * Returns an array of orderNotification objects associated with a specific shift
   *
   * @param shiftID - The ID of the shift to fetch notifications for
   * @returns {Promise<*>}
   */
  getPreorderReminderNotifications(shiftID) {
    return fleatAPI.callAPI({
      url: `schedules/shifts/${shiftID}/preorder-reminders`,
      method: `GET`,
    });
  }

  /**
   * Sends reminder notifications for preorder associated with a specific shift
   *
   * @param shiftID - The ID of the shift to send preorder reminder notifications for
   * @returns {Promise<*>}
   */
  sendPreorderReminders(shiftID) {
    const today = moment().startOf('day').toISOString();
    return fleatAPI.callAPI({
      url: `schedules/shifts/${shiftID}/preorder-reminders?today=${today}`,
      method: `POST`,
      data: {},
    });
  }
}

export const OrderNotifications = new OrderNotificationsModel();
export default OrderNotifications;

