import _get from 'lodash/get';
import { combineReducers } from 'redux';
import * as types from '../actions/types';

// ------------------------------------
// SELECTORS
// ------------------------------------
export const depotAppDepotsSelector = (state) => {
  return _get(state, 'depotApp.depots.data', []);
};


// ------------------------------------
// ACTIONS
// ------------------------------------
export function storeDepotAppDepots(payload) {
  return {
    type: types.SET_DEPOT_APP_DEPOTS,
    payload,
  };
}


// ------------------------------------
// REDUCER
// ------------------------------------
const initialState = {
  loading: false,
  data: [],
};

function loading(state = initialState.loading, action) {
  if (action.type === types.LOADING_DEPOT_APP_DEPOTS) {
    return action.payload;
  }
  return state;
}

function data(state = initialState.data, action) {
  if (action.type === types.SET_DEPOT_APP_DEPOTS) {
    return action.payload;
  }
  return state;
}

export default combineReducers({
  loading,
  data,
});
