const _find = require('lodash/find');
const _intersection = require('lodash/intersection');

// The below will tell eslint to ignore the google variable being undefined
/* global google */

/**
 * Returns the distance between two positions
 *
 * @param position1 {{lat: number, lng: number}}
 * @param position2 {{lat: number, lng: number}}
 * @returns {number}
 */
function getDirectDistance(position1, position2) {
  const latDiff = Math.abs(position1.lat - position2.lat);
  const longDiff = Math.abs(position1.lng - position2.lng);
  return Math.sqrt((latDiff ** 2) + (longDiff ** 2));
}

/**
 * Returns the lat,lng coordinates of the midpoint of two positions
 *
 * @param position1 {{lat: number, lng: number}}
 * @param position2 {{lat: number, lng: number}}
 * @returns {{lat: number, lng: number}}
 */
function getMidpoint(position1, position2) {
  return {
    lat: (position1.lat + position2.lat) / 2,
    lng: (position1.lng + position2.lng) / 2,
  };
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

/**
 * Returns the distance in Kilometres between two Lat/Long points
 * https://www.movable-type.co.uk/scripts/latlong.html
 *
 * Haversine formula:
 *   a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
 *   c = 2 ⋅ atan2( √a, √(1−a) )
 *   d = R ⋅ c
 * where φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
 * note that angles need to be in radians to pass to trig functions!
 *
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns {number}
 */
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in kilometres
  const radLat1 = deg2rad(lat1);
  const radLat2 = deg2rad(lat2);
  const deltaLat = deg2rad(lat2 - lat1);
  const deltaLon = deg2rad(lon2 - lon1);

  const a = (Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)) +
    (Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

function findItemWithType(list, types) {
  return _find(list, (item) => {
    return _intersection(item.types, types).length > 0;
  });
}

function getShortName(address_components, types) {
  const item = findItemWithType(address_components, types);
  return item ? item.short_name : '';
}

/**
 *
 * @param results
 * @returns {*}
 */
function getBestResult(results) {
  const streetAddress = findItemWithType(results, ['street_address']);
  if (streetAddress) {
    return streetAddress;
  }

  const premise = findItemWithType(results, ['premise']);
  if (premise) {
    return premise;
  }

  const route = findItemWithType(results, ['route']);
  if (route) {
    return route;
  }

  const establishment = findItemWithType(results, ['establishment']);
  if (establishment) {
    return establishment;
  }

  if (results && results[0]) {
    return results[0];
  }
}

/**
 *
 * @param results
 * @returns {{street_number: *, route: *, street: string, city: *, state: *, country: *, zip: *, name: *, place_id: (*|string), lat: *, lng: *, formatted}}
 */
function parseGoogleGeocodeResults(results) {
  try {
    const place = getBestResult(results);

    const street_number = getShortName(place.address_components, ['street_number']);
    const route = getShortName(place.address_components, ['route']);
    const city = getShortName(place.address_components, ['neighborhood', 'locality']);
    const state = getShortName(place.address_components, ['administrative_area_level_1']);
    const country = getShortName(place.address_components, ['country']);
    const zip = getShortName(place.address_components, ['postal_code']);

    // Combine the street number and route (i.e. street name) into a single 'street' field
    const street = street_number ? `${street_number} ${route}` : '';

    return {
      street_number,
      route,
      street,
      city,
      state,
      country,
      zip,
      name: place.name,
      place_id: place.place_id,
      lat: typeof place.geometry.location.lat === 'function' ? place.geometry.location.lat() : place.geometry.location.lat,
      lng: typeof place.geometry.location.lng === 'function' ? place.geometry.location.lng() : place.geometry.location.lng,
      formatted: place.formatted_address,
    };
  } catch (error) {
    console.log('parseGoogleGeocodeResults error', error);
  }
}

function pointIsInPolygon(point, polygon) {
  const x = point[0];
  const y = point[1];

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect = ((yi > y) !== (yj > y))
      && (x < (((xj - xi) * (y - yi)) / (yj - yi)) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}

const getAreaOfDeliveryZone = (deliveryZone) => {
  const polygon = new google.maps.Polygon({
    paths: deliveryZone.coordinates,
  });

  const squareMeters = google.maps.geometry.spherical.computeArea(polygon.getPath());
  const squareMiles = squareMeters * 0.000000386102159;
  return Math.round((squareMiles) * 100) / 100;
};


module.exports = {
  getBestResult,
  parseGoogleGeocodeResults,
  pointIsInPolygon,
  getAreaOfDeliveryZone,
};
