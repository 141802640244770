import { depotsAPI } from '../../services/api/depots.apiService';
import { vendorsAPI } from '../../services/api/vendors.apiService';
import firebase from '../../services/firebase.service';
import * as AuthUser from '../../services/models/auth-user.model';
import { fetchCart, clearCart, guestCartReady } from '../customerApp/cart';
import { storeDepotAppDepot } from '../depotApp/depot';
import { storeDepotAppDepots } from '../depotApp/depots';
import { storeDepotAppVendor } from '../depotApp/vendor';
import { connectedVendorSelector } from '../selectors/customer/connectedVendor.selectors';
import { profileSelector } from '../selectors/user.selector';
import { storeVendorAppVendor } from '../vendorApp/vendor';
import { initializeDeliveryAddress } from './customerPosition.actions';
import { useRouterParams } from '../../hooks/router.hooks';
import {
  startWatchingInventory,
  watchOrdersForVehicle,
  watchDriverVehicle,
} from './driver.actions';
import { watchDriverPosition, watchDriverVehiclePosition } from './driverPosition.actions';
import { fetchRelevantShiftForVehicle } from './driverShifts.actions';
import * as types from './types';
import { initializeOAuthListeners, joinCredentials, watchAuthState } from './auth.actions';
import { fetchProfile } from './user.actions';
import { fetchVendor } from './vendor.actions';
import { storeNearby } from '../../data/actions/nearby.actions';
import moment from 'moment';

export function applicationError(options) {
  return {
    type: types.APP_ERROR,
    ...options,
  };
}

export function setInitialized(item) {
  return {
    type: types.SET_INITIALIZED,
    item,
  };
}

function storeConfig(config) {
  return {
    type: types.CONFIG_CHANGED,
    config,
  };
}

function watchConfigState() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const ref = firebase.database().ref('/config');
      ref.on('value', (snapshot) => {
        dispatch(storeConfig(snapshot.val()));
        dispatch(setInitialized('config'));
        resolve(snapshot.val());
      });
    });
  };
}

// INIT APPLICATION
export function initApplication(vendorId) {
  return async (dispatch, getState) => {
    try {
      dispatch(initializeOAuthListeners());
      dispatch(storeNearby(null));
      await dispatch(watchConfigState());
      await dispatch(fetchVendor(vendorId));
      await dispatch(initUser());
    } catch (error) {
      console.log('initApplication error', error);
    }
    // setTimeout(() => {
    dispatch(setInitialized('app'));
    // }, 3000);
  };
}

// INIT USER
export function initUser() {
  return async (dispatch, getState) => {
    try {
      // Check if user is logged in
      const user = await dispatch(watchAuthState());
      dispatch(setInitialized('auth'));

      // If user is logged in, fetch their profile, and check for 3rd party credentials that may need to be merged
      if (user) {
        await AuthUser.joinCredentials();
      }
    } catch (error) {
      console.log('initUser error', error);
    }
  };
}

export function initCustomer(user) {
  return async (dispatch, getState) => {
    if (user) {
      await dispatch(fetchProfile());
      const profile = profileSelector(getState());

      // Initialize delivery address
      await dispatch(initializeDeliveryAddress());
      return profile;
    }
    if (!user) {
      // Initialize delivery address
      await dispatch(initializeDeliveryAddress());
    }
  };
}

export function initCustomerCart(user) {
  return async (dispatch, getState) => {
    if (user) {
      const cart = await dispatch(fetchCart());
      if (cart.lastUpdatedAt) {
        const date = moment(cart.lastUpdatedAt);
        const diff = moment().diff(date, 'hours');
        if (diff > 24) {
          dispatch(clearCart());
        }
      }
    }
    if (!user) {
      await dispatch(guestCartReady());
    }
  };
}

export function initDriver(vehicleID) {
  return async (dispatch) => {
    return Promise.all([
      // listen for changes to vehicle inventory
      dispatch(startWatchingInventory(vehicleID)),
      // listen for changes to vehicle status
      dispatch(watchDriverVehicle(vehicleID)),
      // listen for changes to vehicle position
      dispatch(watchDriverVehiclePosition(vehicleID)),
      // fetch vehicle shift info
      dispatch(fetchRelevantShiftForVehicle(vehicleID)),
    ]);
  };
}

export function initDepot(depotID, vendorID) {
  return async (dispatch) => {
    const depots = await depotsAPI.getByVendorID(vendorID);
    const vendor = await vendorsAPI.getVendorByID(vendorID);
    dispatch(storeDepotAppDepots(depots));
    dispatch(storeDepotAppVendor(vendor));
    let depot = null;
    if (depotID) {
      depot = await depotsAPI.getByID(depotID);
      dispatch(storeDepotAppDepot(depot));
    }
    return { depot, vendor };
  };
}

export function initVendor(user) {
  return async (dispatch, getState) => {
    const vendor = connectedVendorSelector(getState());
    dispatch(storeVendorAppVendor(vendor));
  };
}
