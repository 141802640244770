import moment from 'moment';
import API from '../api.service';

/**
 * Fetches an object containing two lists of customers that have at least one address within the provided delivery zone:
 * a list of eligible flash notifications, and a list of ineligible flash notifications
 *
 * @param deliveryZoneID - The id of the delivery zone
 * @param today - date object used to filter out customers who have placed an order after the given date
 * @returns {Promise<Array>}
 */
function getFlashCustomers(deliveryZoneID) {
  // Date used to filter out customers who have placed an order after the given date
  const startOfToday = moment().startOf('day').toISOString();
  return API.callAPI({
    url: `flash/customers?deliveryZoneID=${deliveryZoneID}&today=${startOfToday}`,
    method: 'get',
  });
}

/**
 * Deletes a Vendor-defined Flash Notification message
 *
 * @param messageID - ID of the Flash Notification Message to delete
 * @returns {Promise<*>}
 */
function deleteFlashMessage(messageID) {
  return API.callAPI({
    url: `flash/messages/${messageID}`,
    method: 'delete',
  });
}

/**
 * Creates a Vendor-defined Flash Notification message
 *
 * @param payload
 * @returns {Promise<[]>} - Array of Vendor-defined Flash Notification messages
 */
function createFlashMessage(payload) {
  return API.callAPI({
    url: `flash/messages`,
    method: 'post',
    data: payload,
  });
}

/**
 * Creates a Vendor-defined Flash Notification message
 *
 * @param payload
 * @returns {Promise<[]>} - Array of Vendor-defined Flash Notification messages
 */
function editFlashMessage(messageID, payload) {
  return API.callAPI({
    url: `flash/messages/${messageID}`,
    method: 'put',
    data: payload,
  });
}

/**
 * Fetches a list of Vendor-defined Flash Notification messages
 *
 * @param vendorID
 * @returns {Promise<[]>} - Array of Vendor-defined Flash Notification messages
 */
function getFlashMessages(vendorID) {
  return API.callAPI({
    url: `flash/messages?vendorID=${vendorID}`,
    method: 'get',
  });
}

/**
 * Fetches a Vendor-defined Flash Notification message
 *
 * @param vendorID
 * @param messageID
 * @returns {Promise<[]>} - Array of Vendor-defined Flash Notification messages
 */
function getFlashMessage(vendorID, messageID) {
  return API.callAPI({
    url: `flash/messages/${messageID}?vendorID=${vendorID}`,
    method: 'get',
  });
}

/**
 * Fetches a list of Delivered Flash Notification messages sent on this day, for the given vendor
 *
 * @param params - {vendorID, fromDate, toDate}
 * @returns {Promise<[]>} - Array of Delivered Flash Notification messages
 */
async function getDeliveredFlashMessages({ vendorID, shiftID = '', fromDate = '', toDate = '', depotID = '' }) {
  const notifications = await API.callAPI({
    url: `flash/delivered?vendorID=${vendorID}&fromDate=${fromDate}&toDate=${toDate}&depotID=${depotID}&shiftID=${shiftID}`,
    method: 'get',
  });
  return notifications.map((n) => {
    return {
      ...n,
      createdAt: new Date(n.createdAt),
    };
  });
}

/**
 * Sends the given flash message to the provided profile IDs, and saves the deliveryZone ID in the sent message record
 * for reference
 *
 * @param profileIDs
 * @param deliveryZoneID
 * @param flashOfferMessageID
 * @param today - date object used to filter out customers who have placed an order after the given date
 * @returns {*}
 */
function sendFlashNotifications(profileIDs, deliveryZoneID, flashOfferMessageID, today) {
  return API.callAPI({
    url: `flash/send`,
    method: 'post',
    data: {
      profileIDs,
      deliveryZoneID,
      flashOfferMessageID,
      today,
    },
  });
}

/**
 * Creates a new shift notification, to be sent to eligible customers now or later, depending on the "sendAfter" value
 *
 * @param shiftID
 * @param payload
 * @returns {*}
 */
function createShiftNotification(shiftID, payload) {
  // Date used to filter out customers who have placed an order after the given date
  const startOfToday = moment().startOf('day').toISOString();
  return API.callAPI({
    url: `flash/shifts/${shiftID}`,
    method: 'post',
    data: {
      ...payload,
      today: startOfToday,
    },
  });
}

/**
 * Fetches a list of sent and scheduled notifications for a given shift
 *
 * @param shiftID
 * @returns {*}
 */
function fetchShiftNotifications(shiftID) {
  // Date used to filter out customers who have placed an order after the given date
  return API.callAPI({
    url: `flash/shifts/${shiftID}`,
    method: 'get',
  });
}

export const flashNotificationsAPI = {
  getFlashCustomers,
  getFlashMessages,
  getFlashMessage,
  getDeliveredFlashMessages,
  sendFlashNotifications,
  deleteFlashMessage,
  createFlashMessage,
  editFlashMessage,
  createShiftNotification,
  fetchShiftNotifications,
};

export default flashNotificationsAPI;
