import qs from 'qs';
import API from '../api.service';

class ZonesAPI {

  STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  };

  /**
   * Returns a DeliveryZone object
   *
   * @param deliveryZoneID - The ID of the Delivery Zone
   * @param includeCustomers - whether to include customer data matching this zone
   * @returns {Promise<{}>}
   */
  async getZoneByID(deliveryZoneID, includeCustomers = false) {
    return API.callAPI({
      method: 'GET',
      url: `/zones/${deliveryZoneID}?includeCustomers=${!!includeCustomers}`,
    });
  }

  /**
   * Returns an array of DeliveryZone objects for a given Depot
   *
   * @param params {Object}
   * @returns {Promise<[]>}
   */
  async getZonesByParams(params) {
    const response = await API.callAPI({
      url: `/zones?${qs.stringify(params)}`,
    });
    return response.map((shift) => {
      return {
        ...shift,
        createdAt: new Date(shift.createdAt),
        updatedAt: new Date(shift.updatedAt),
      };
    });
  }

  /**
   * Creates a new delivery zone
   *
   * @param payload {object}
   * @returns {Promise<*>}
   */
  async create(payload) {
    return API.callAPI({
      method: 'POST',
      url: `/zones/`,
      data: payload,
    });
  }

  /**
   * Updates a DeliveryZone
   *
   * @param deliveryZone - The Delivery Zone object to update
   * @returns {Promise<*>}
   */
  async update(deliveryZone) {
    return API.callAPI({
      method: 'PATCH',
      url: `/zones/${deliveryZone.id}`,
      data: deliveryZone,
    });
  }

  /**
   * Deletes a DeliveryZone
   *
   * @param deliveryZoneID - The ID of the Delivery Zone
   * @returns {Promise<*>}
   */
  async remove(deliveryZoneID) {
    return API.callAPI({
      method: 'DELETE',
      url: `/zones/${deliveryZoneID}`,
    });
  }
}

export const zonesAPI = new ZonesAPI();
