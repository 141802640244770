export function roundFloat(amount, precision) {
  let multiplier = 10 ** 2;
  if (precision) {
    multiplier = 10 ** precision;
  }
  const float = Number.parseFloat(amount);
  return Math.round(float * multiplier) / multiplier;
}

export function convertToDollarString(amount) {
  return `$${Number.parseFloat(roundFloat(amount)).toFixed(2)}`;
}
