import { combineReducers } from 'redux';
import customers from './customers.reducer';
import messages from './messages.reducer';
import delivered from './delivered.reducer';
import notifications from './notifications.reducer';

export default combineReducers({
  customers,
  messages,
  delivered,
  notifications,
});
