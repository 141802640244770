import moment from 'moment';
import qs from 'qs';
import { fleatAPI } from '../api.service';

class OrdersAPI {

  formatOrder(order = {}) {
    return {
      ...order,
      createdAt: order.createdAt ? new Date(order.createdAt) : null,
      updatedAt: order.updatedAt ? new Date(order.updatedAt) : null,
      deliveredAt: order.deliveredAt ? new Date(order.deliveredAt) : null,
      deliverAfter: order.deliverAfter ? new Date(order.deliverAfter) : null,
      estimatedDeliveryTime: order.estimatedDeliveryTime ? new Date(order.estimatedDeliveryTime) : null,
      originalEstimatedDeliveryTime: order.originalEstimatedDeliveryTime ? new Date(order.originalEstimatedDeliveryTime) : null,
    };
  }

  formatOrders(orders = []) {
    return orders.map(this.formatOrder);
  }

  async getEstimatedBillDetails(paylaod) {
    return fleatAPI.callAPI({
      url: `orders/billdetails`,
      method: `POST`,
      data: paylaod,
    });
  }

  async fetchOrdersForAdmin(params) {
    const response = await fleatAPI.callAPI({
      url: `orders/admin?${qs.stringify(params)}`,
      method: `GET`,
    });
    return this.formatOrders(response);
  }

  async fetchOrdersForVendor(vendorID, params) {
    const response = await fleatAPI.callAPI({
      url: `orders/vendor/${vendorID}?${qs.stringify(params)}`,
      method: `GET`,
    });
    return this.formatOrders(response);
  }

  async fetchOrdersForDepot(depotID, params) {
    const response = await fleatAPI.callAPI({
      url: `orders/depot/${depotID}?${qs.stringify(params)}`,
      method: `GET`,
    });
    return this.formatOrders(response);
  }

  async fetchOrdersForShift(shiftID) {
    const response = await fleatAPI.callAPI({
      url: `orders/shift/${shiftID}`,
      method: `GET`,
    });
    return {
      active: this.formatOrders(response.active),
      closed: this.formatOrders(response.closed),
      upcoming: this.formatOrders(response.upcoming),
    };
  }

  async fetchPreordersEligibleForRemindersForShift(shiftID) {
    const today = moment().startOf('day').toISOString();
    const response = await fleatAPI.callAPI({
      url: `orders/shift/${shiftID}/reminder-eligible?today=${today}`,
      method: `GET`,
    });
    return this.formatOrders(response);
  }

  async fetchPublicOrdersForShift(shiftID) {
    const response = await fleatAPI.callAPI({
      url: `orders/public/shift/${shiftID}`,
      method: `GET`,
    });
    return this.formatOrders(response);
  }

  async fetchOrdersForProfile(profileID, params) {
    const response = await fleatAPI.callAPI({
      url: `orders/profile/${profileID}?${qs.stringify(params)}`,
      method: `GET`,
    });
    return this.formatOrders(response);
  }

  submitOrder(order) {
    return fleatAPI.callAPI({
      url: `orders`,
      method: `POST`,
      data: order,
    });
  }

  submitOrderOnBehalfOfCustomer(profileID, order) {
    return fleatAPI.callAPI({
      url: `orders/profile/${profileID}`,
      method: `POST`,
      data: order,
    });
  }

  submitModifiedOrder(order) {
    return fleatAPI.callAPI({
      url: `orders/${order.id}`,
      method: `PUT`,
      data: order,
    });
  }

  completeOrder(order) {
    return fleatAPI.callAPI({
      url: `orders/${order.id}/complete`,
      method: `PATCH`,
    });
  }

  cancelOrder(order) {
    return fleatAPI.callAPI({
      url: `orders/${order.id}/cancel`,
      method: `PATCH`,
    });
  }

  reOpenOrder(order) {
    return fleatAPI.callAPI({
      method: `PATCH`,
      url: `orders/${order.id}/re-open`,
    });
  }

  forceToQueue(order) {
    return fleatAPI.callAPI({
      method: `PATCH`,
      url: `orders/${order.id}/force-to-queue`,
    });
  }

  sendOrderNotification(orderID, type) {
    return fleatAPI.callAPI({
      url: `orders/${orderID}/notifications`,
      method: 'POST',
      data: {
        type,
      },
    });
  }
}

export const ordersAPI = new OrdersAPI();
