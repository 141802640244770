import moment from 'moment';
import qs from 'qs';
import API, { fleatAPI } from '../api.service';

class ShiftsAPI {
  constructor() {
    this.TYPE = {
      PICKUP: 'PICKUP',
      DELIVERY: 'DELIVERY',
    };
    this.STATUS = {
      DRAFT: 'DRAFT',
      PUBLISHED: 'PUBLISHED',
      LOADING: 'LOADING',
      ACTIVE: 'ACTIVE',
      COMPLETED: 'COMPLETED',
      CANCELLED: 'CANCELLED',
    };
  }

  /**
   *
   * @param vendorID
   * @param lat
   * @param lng
   * @returns {Promise<void>}
   */
  getNearbyShifts(vendorID, lat, lng) {
    return API.callAPI({
      method: 'get',
      url: `nearby/${vendorID}?lat=${lat}&lng=${lng}`,
    });
  }

  async getShiftsByParams(params) {
    const response = await API.callAPI({
      method: 'get',
      url: `/schedules/shifts?${qs.stringify(params)}`,
    });
    return response.records.map((shift) => {
      return {
        ...shift,
        startDate: new Date(shift.startDate),
        endDate: new Date(shift.endDate),
      };
    });
  }

  async getUpcomingShiftsByParams(params) {
    const publishedShifts = await this.getShiftsByParams({
      ...params,
      status: [this.STATUS.PUBLISHED],
      endDateAfter: moment().toISOString(),
      limit: 25,
      orderBy: 'startDate',
      direction: 'ASC',
    });
    const activeShifts = await this.getShiftsByParams({
      ...params,
      status: [this.STATUS.LOADING, this.STATUS.ACTIVE],
      limit: 25,
      orderBy: 'startDate',
      direction: 'ASC',
    });
    return activeShifts.concat(publishedShifts);
  }

  /**
   * Fetch the current shift (shift STATUS is LOADING or ACTIVE) for a vehicle
   *
   * @param vehicleID
   * @return {Promise<*>}
   */
  async getUpcomingShiftsForVehicle(vehicleID) {
    const shifts = await API.callAPI({
      method: 'get',
      url: `vehicles/${vehicleID}/shifts/upcoming`,
    });
    return shifts.map((shift) => {
      return {
        ...shift,
        startDate: new Date(shift.startDate),
        endDate: new Date(shift.endDate),
      };
    });
  }

  /**
   * Fetch all upcoming shifts (shift STATUS is PUBLISHED) for a vehicle
   *
   * @param vehicleID
   * @return {Promise<*>}
   */
  async getCurrentShiftForVehicle(vehicleID) {
    const shift = await API.callAPI({
      method: 'get',
      url: `/vehicles/${vehicleID}/shifts/current`,
    });
    if (shift) {
      return {
        ...shift,
        startDate: new Date(shift.startDate),
        endDate: new Date(shift.endDate),
      };
    }
    return null;
  }

  /**
   * Fetch the currently active shift (LOADING or ACTIVE) or, the soonest upcoming shift (PUBLISHED) for a vehicle
   *
   * @param vehicleID
   * @return {Promise<*>}
   */
  async getRelevantShiftForVehicle(vehicleID) {
    const [
      upcomingShifts,
      shift,
    ] = await Promise.all([
      this.getUpcomingShiftsForVehicle(vehicleID),
      this.getCurrentShiftForVehicle(vehicleID),
    ]);
    if (shift) {
      return shift;
    }
    if (upcomingShifts.length > 0) {
      return upcomingShifts[0];
    }
    return null;
  }

  async getShiftsForUser(userID) {
    const shifts = await API.callAPI({
      method: 'get',
      url: `/users/${userID}/shifts`,
    });
    return shifts.map((shift) => {
      return {
        ...shift,
        startDate: new Date(shift.startDate),
        endDate: new Date(shift.endDate),
      };
    });
  }

  /**
   * Returns an array of preorder time-window options associated with a specific shift
   *
   * @param shiftID - The ID of the shift to fetch preorder time-windows for
   * @returns {Promise<*>}
   */
  getShiftPreorderTimes(shiftID) {
    return fleatAPI.callAPI({
      url: `schedules/shifts/${shiftID}/preorder-times`,
      method: `GET`,
    });
  }

  /**
   * Returns a key:value structured object, indicating the amount of menu parts purchased for the given shift
   *
   * @param shiftID - The ID of the shift to retrieve purchased quantities for
   * @returns {Promise<*>}
   */
  getShiftPreorderQuantities(shiftID) {
    return fleatAPI.callAPI({
      url: `schedules/shifts/${shiftID}/preorder-quantities`,
      method: `GET`,
    });
  }

  /**
   * Returns the amount of menu parts remaining for the given shift (based on menu shift quantities, and preorders)
   * NOTE: This should only be used when a shift is eligible for PREORDERS (i.e. before the shift is ACTIVE)
   *
   * @param shiftID - The ID of the shift to retrieve available preorder quantities for
   * @returns {Promise<*>}
   */
  getShiftPreorderAvailableQuantities(shiftID) {
    return fleatAPI.callAPI({
      url: `schedules/shifts/${shiftID}/available-quantities`,
      method: `GET`,
    });
  }

  loadShift(vehicleID, shiftID) {
    return API.callAPI({
      url: `vehicles/${vehicleID}/shifts/${shiftID}/load`,
      method: 'patch',
    });
  }

  goOnline(vehicleID) {
    return API.callAPI({
      url: `/vehicles/${vehicleID}/go-online`,
      method: 'patch',
    });
  }

  goOffline(vehicleID) {
    return API.callAPI({
      url: `/vehicles/${vehicleID}/go-offline`,
      method: 'patch',
    });
  }

  endShift(vehicleID, shiftID) {
    return API.callAPI({
      url: `vehicles/${vehicleID}/shifts/${shiftID}/end`,
      method: 'patch',
    });
  }

  async getShiftById(shiftID) {
    const shift = await API.callAPI({
      method: 'get',
      url: `schedules/shifts/${shiftID}`,
    });
    return {
      ...shift,
      startDate: new Date(shift.startDate),
      endDate: new Date(shift.endDate),
    };
  }

  createShift(data) {
    return API.callAPI({
      url: `schedules/shifts`,
      method: 'post',
      data,
    });
  }

  editShift(shiftID, data) {
    return API.callAPI({
      url: `schedules/shifts/${shiftID}`,
      method: 'put',
      data,
    });
  }

  deleteShift(shiftID) {
    return API.callAPI({
      method: 'delete',
      url: `schedules/shifts/${shiftID}`,
    });
  }

  getShiftReport(shiftID) {
    return API.callAPI({
      method: 'get',
      url: `schedules/shifts/${shiftID}/report`
    }, 'blob');
  }
}

export const shiftsAPI = new ShiftsAPI();
export default shiftsAPI;
