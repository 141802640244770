import Listener from '../../helpers/Listener';
import firebase from '../firebase.service';
import FirestoreSanitizer from './FirestoreSanitizer';
import { Inventory } from './inventory.model';

class VehiclesFS {
  constructor() {
    this.sanitize = new FirestoreSanitizer(this.COLLECTION_PROPS);
  }

  // ***
  // Module Constants
  // ***

  /**
   * Firestore collection name
   *
   * @type {string}
   */
  COLLECTION_NAME = 'vehicles';

  /**
   * Firestore reference to this collection
   *
   * @type {firebase.firestore.CollectionReference}
   */
  collectionRef = firebase.firestore().collection(this.COLLECTION_NAME);

  /**
   * List of available properties for Delivery Vehicle objects
   *
   * @type {string[]}
   */
  COLLECTION_PROPS = [
    'id',
    'vendorID',
    'depotID',
    'deliveryZoneID',
    'menuID',
    'status',
    'imageURL',
    'dwellTime',
    'minimumOrderAmount',
    'displayName',
    'description',
    'phoneNumber',
    'licensePlate',
  ];

  VEHICLE_STATUS = {
    LOADING: 'LOADING',
    OFFLINE: 'OFFLINE',
    CLOSED: 'CLOSED',
    OPEN: 'DELIVERY',
    STATIONARY: 'PICKUP',
  };

  /**
   * List of allowed Delivery Vehicle sub-collections
   *
   * @type {string[]}
   */
  SUB_COLLECTIONS = [
    Inventory,
  ];

  subscriptions = {
    dv: () => null,
    dvPos: () => null,
  };


  async list(vendorID) {
    const deliveryVehicleSnapshot = await this.collectionRef
      .where('vendorID', '==', vendorID)
      .get();

    return this.sanitize.collectionSnapshot(deliveryVehicleSnapshot);
  }

  async getVehiclesForDepots(depotID) {
    const deliveryVehicleSnapshot = await this.collectionRef
      .where('depotID', '==', depotID)
      .get();

    return this.sanitize.collectionSnapshot(deliveryVehicleSnapshot);
  }

  async getShallowDeliveryVehicleByID(vehicleID) {
    const dvRef = this.collectionRef.doc(vehicleID);
    const snapshot = await dvRef.get();
    return this.sanitize.document(snapshot);
  }

  /**
   * Returns a Delivery Vehicle's position
   *
   * @param id - Firestore ID of the delivery vehicle to fetch the position of
   * @returns {Promise<*>}
   */
  async getPosition(id) {
    const snapshot = await firebase.database().ref(`delivery-vehicle-locations/${id}`).once('value');
    return snapshot.val().l;
  }

  watchShallowDeliveryVehicleByID(vehicleID) {
    return new Listener((onUpdate, onError) => {
      const dvRef = this.collectionRef.doc(vehicleID);

      // Cancel any existing firestore watchers
      this.subscriptions.dv();
      this.subscriptions.dv = dvRef.onSnapshot((doc) => {
        const deliveryVehicle = this.sanitize.document(doc);
        onUpdate(deliveryVehicle);
      }, onError);
    });
  }

  updateVehicleMenu(vehicleID, menuID) {
    return this.collectionRef
      .doc(vehicleID)
      .update({ menuID });
  }

  assignZoneToVehicle(vehicleID, deliveryZoneID) {
    const dvRef = this.collectionRef.doc(vehicleID);

    if (!deliveryZoneID) {
      throw new Error('deliveryZoneID is required');
    }

    return dvRef.update(this.sanitize.data({ deliveryZoneID }));
  }

  watchVehiclePosition(vehicleID) {
    return new Listener((onUpdate, onError) => {
      const dvPosRef = firebase.database().ref(`delivery-vehicle-locations/${vehicleID}/l`);

      // Cancel any existing firebase watchers
      this.subscriptions.dvPos();
      this.subscriptions.dvPos = dvPosRef.on('value', (doc) => {
        if (doc && doc.toJSON) {
          const data = doc.toJSON();
          onUpdate({
            lat: data[0],
            lng: data[1],
          });
        }
      }, onError);
    });
  }

  getInventory(vehicleID) {
    const dvRef = this.collectionRef.doc(vehicleID);
    return Inventory.get(dvRef);
  }

  watchInventory(vehicleID) {
    const dvRef = this.collectionRef.doc(vehicleID);
    return Inventory.watch(dvRef);
  }

  adjustInventoryQuantity(vehicleID, partID, adjustmentAmount) {
    const dvRef = this.collectionRef.doc(vehicleID);
    return Inventory.updateQuantity(dvRef, partID, adjustmentAmount);
  }

  setInventoryQuantity(vehicleID, partID, availableQuantity) {
    const dvRef = this.collectionRef.doc(vehicleID);
    return Inventory.setQuantity(dvRef, partID, availableQuantity);
  }
}

export const vehiclesFS = new VehiclesFS();
