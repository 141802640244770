import { combineReducers } from 'redux';
import * as types from '../../../actions/types';

const initialStateSending = false;

function sending(state = initialStateSending, action) {
  switch (action.type) {
    case types.SENDING_FLASH_NOTIFICATIONS:
      return action.data;
    default:
      return state;
  }
}

const initialStateError = null;

function error(state = initialStateError, action) {
  switch (action.type) {
    case types.SET_FLASH_NOTIFICATION_ERROR:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  sending,
  error,
});
