import _get from 'lodash/get';
import { combineReducers } from 'redux';
import { shiftsAPI } from '../../services/api/shifts.apiService';
import * as types from '../actions/types';
import { promosAPI } from '../../services/api/promos.apiService';


// ------------------------------------
// REDUCER
// ------------------------------------
const initialState = {
  loading: false,
  data: null,
};

function loading(state = initialState.loading, action) {
  if (action.type === types.LOADING_CONNECTED_PROMO) {
    return action.payload;
  }
  return state;
}

function data(state = initialState.data, action) {
  if (action.type === types.SET_CONNECTED_PROMO) {
    return action.payload;
  }
  return state;
}

export default combineReducers({
  loading,
  data,
});


// ------------------------------------
// SELECTORS
// ------------------------------------
export const connectedPromoLoadingSelector = (state) => {
  return _get(state, 'customerApp.connectedPromo.loading', initialState.loading);
};
export const connectedPromoSelector = (state) => {
  return _get(state, 'customerApp.connectedPromo.data', initialState.data);
};


// ------------------------------------
// ACTIONS
// ------------------------------------
export function storeConnectedPromoLoading(payload) {
  return {
    type: types.LOADING_CONNECTED_PROMO,
    payload,
  };
}

export function storeConnectedPromo(payload) {
  return {
    type: types.SET_CONNECTED_PROMO,
    payload,
  };
}

export function fetchConnectedPromo(shiftID) {
  return (dispatch) => {
    dispatch(storeConnectedPromoLoading(true));
    promosAPI.fetchMyAvailablePromosForShift(shiftID)
      .then((promos) => {
        dispatch(storeConnectedPromo(promos[0] || null));
        dispatch(storeConnectedPromoLoading(false));
      })
      .catch((error) => {
        console.warn('fetchMyAvailablePromosForShift error', error);
        dispatch(storeConnectedPromoLoading(false));
      });
  };
}

export function fetchConnectedPromoForVehicle(vehicleID) {
  return async (dispatch) => {
    try {
      const shift = await shiftsAPI.getRelevantShiftForVehicle(vehicleID);
      dispatch(fetchConnectedPromo(shift.id));
    } catch (error) {
      console.warn('fetchConnectedPromoForVehicle error', error);
    }
  };
}
