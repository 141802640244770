import { combineReducers } from 'redux';
import * as types from '../../../actions/types';

const initialState = {
  data: [],
};

function data(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_SHIFT_PREORDERS:
      return action.data;
    default:
      return state;
  }
}

function sentReminders(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_SHIFT_SENT_PREORDER_REMINDERS:
      return action.data;
    default:
      return state;
  }
}

function reminderEligible(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_SHIFT_REMINDER_ELIGIBLE_PREORDERS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  data,
  sentReminders,
  reminderEligible,
});
