import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect, Switch,
} from 'react-router-dom';
import { connectedVendorSelector } from '../../../data/selectors/customer/connectedVendor.selectors';
import { getIsAppInitFinished, getIsAppOnline } from '../../../data/selectors/initialized.selector';
import { composedUserSelector } from '../../../data/selectors/user.selector';
import { RouterHookProvider } from '../../../hooks/router.hooks';
import { GenericSplash } from '../molecules/Splash/GenericSplash';

// components load their module for initial visit
const ComingSoonScreen = React.lazy(() => import('../../customer-app/screens/ComingSoonScreen/ComingSoonScreen'));
const CustomerApp = React.lazy(() => import('../../customer-app/CustomerApp'));
const DriverApp = React.lazy(() => import('../../driver-app/DriverApp'));
const VendorApp = React.lazy(() => import('../../vendor-app/VendorApp'));
const AdminApp = React.lazy(() => import('../../admin-app/AdminApp'));
const DepotApp = React.lazy(() => import('../../depot-app/DepotApp'));
const SharedApp = React.lazy(() => import('../SharedApp'));

AppRouter.propTypes = {};

export function AppRouter(props) {
  const user = useSelector(composedUserSelector);
  const isAppReady = useSelector(getIsAppInitFinished);
  const isAppOnline = useSelector(getIsAppOnline);

  function renderAlways(Screen) {
    return () => {
      return (
        <RouterHookProvider>
          <Suspense fallback={<GenericSplash />}>
            <Screen />
          </Suspense>
        </RouterHookProvider>
      );
    };
  }

  function renderIfLoggedIn(Screen) {
    return () => {
      const isLoggedIn = !!user;
      if (isLoggedIn) {
        return (
          <RouterHookProvider>
            <Suspense fallback={<GenericSplash />}>
              <Screen />
            </Suspense>
          </RouterHookProvider>
        );
      }
      return (
        <Redirect to="/" />
      );
    };
  }

  if (!isAppReady) {
    return null;
  }

  if (isAppOnline === false) {
    return (
      <ComingSoonScreen />
    );
  }

  return (
    <Router>
      <Switch>

        {/* FLEAT ADMIN ROUTES */}
        <Route
          path="/admin"
          render={renderIfLoggedIn(AdminApp)}
        />

        {/* DEPOT ROUTES */}
        <Route
          exact
          path="/vendor/:vendorID/depot"
          render={renderIfLoggedIn(DepotApp)}
        />
        <Route
          path="/vendor/:vendorID/depot/:depotID"
          render={renderIfLoggedIn(DepotApp)}
        />

        {/* VENDOR ROUTES */}
        <Route
          path="/vendor"
          render={renderIfLoggedIn(VendorApp)}
        />

        {/* EMPLOYEE ROUTES */}
        <Route
          path="/driver"
          render={renderIfLoggedIn(DriverApp)}
        />

        {/* CUSTOMER ROUTES */}
        <Route
          path="/store"
          render={renderAlways(CustomerApp)}
        />
        <Route
          path="/menu"
          render={renderAlways(CustomerApp)}
        />

        {/* SHARED ROUTES */}
        <Route
          path="/"
          render={renderAlways(SharedApp)}
        />

      </Switch>
    </Router>
  );
}
