import getMuiTheme from 'material-ui/styles/getMuiTheme';
// https://cimdalli.github.io/mui-theme-generator/

const colors = {
  fleatPrimary: '#2C7DBB',
  success: '#4CAF50',
  error: '#dd2c00',
};

export const defaultTheme = getMuiTheme({
  fontFamily: '"Roboto", sans-serif',
  palette: {
    primary1Color: colors.fleatPrimary,
    primary2Color: colors.fleatPrimary,
    pickerHeaderColor: colors.fleatPrimary,
    success: colors.success,
    error: colors.error,
    info: colors.fleatPrimary,
  },
  appBar: {
    color: '#fff',
    textColor: '#000',
  },
  colors: {
    success: colors.success,
    error: colors.error,
    info: colors.fleatPrimary,
  },
  flatButton: {
    primaryTextColor: colors.fleatPrimary,
    secondaryTextColor: '#000000',
  },
  raisedButton: {
    primaryColor: colors.fleatPrimary,
    secondaryColor: '#F1F2F4',
    secondaryTextColor: '#000000',
  },
  toolbar: {
    backgroundColor: 'transparent',
    color: '#000',
  },
  tabs: {
    backgroundColor: '#323232',
    textColor: 'rgba(255,255,255,.6)',
    selectedTextColor: '#fff',
  },
  inkBar: {
    backgroundColor: colors.fleatPrimary,
  },
  tableHeader: {
    borderBottomColor: colors.fleatPrimary,
  },
  tableHeaderColumn: {
    textColor: '#000',
    height: 56,
    spacing: 24,
  },
  tableRow: {
    selectedColor: '#fff',
  },
  textField: {
    focusColor: colors.fleatPrimary,
    backgroundColor: 'transparent',
  },
  checkbox: {
    checkedColor: colors.fleatPrimary,
  },
  menuItem: {
    selectedTextColor: colors.fleatPrimary,
  },
});
