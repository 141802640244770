import { fleatAPI } from '../api.service';

class DepotsAPI {
  async getByID(depotID) {
    return await fleatAPI.callAPI({
      method: 'GET',
      url: `/depots/${depotID}`,
    });
  }

  async getByVendorID(vendorID) {
    return await fleatAPI.callAPI({
      method: 'GET',
      url: `/depots?vendorID=${vendorID}`,
    });
  }

  async createDepot(payload) {
    return await fleatAPI.callAPI({
      method: 'POST',
      url: `/depots`,
      data: payload,
    });
  }

  async updateDepot(depotID, payload) {
    return await fleatAPI.callAPI({
      method: 'PATCH',
      url: `/depots/${depotID}`,
      data: payload,
    });
  }
}

export const depotsAPI = new DepotsAPI();
