import { combineReducers } from 'redux';
import initialized from './reducers/initialized.reducer';
import config from './reducers/config.reducer';
import user from './reducers/user.reducer';
import profile from './reducers/profile.reducer';
import deliveryVehiclePositions from './reducers/deliveryVehiclePositions.reducer';
import inventory from './reducers/inventory.reducer';
import customer from './reducers/customer.reducer';
import driver from './reducers/driver.reducer';
import voice from './reducers/voice.reducer';
import customerApp from './customerApp/_reducer';
import depotApp from './depotApp/_reducer';
import vendorApp from './vendorApp/_reducer';

const rootReducer = combineReducers({
  initialized,
  config,
  user,
  profile,
  deliveryVehiclePositions,
  inventory,
  customer,
  driver,
  voice,
  customerApp,
  depotApp,
  vendorApp,
});

export default rootReducer;
