import { combineReducers } from 'redux';
import * as types from '../actions/types';

const initialState = {
  loading: false,
  data: null,
};

function loading(state = initialState.loading, action) {
  switch (action.type) {
    case types.LOADING_AUTH_USER:
      return action.loading;
    default:
      return state;
  }
}

function data(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_AUTH_USER:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  data,
});
