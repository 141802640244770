import React from 'react';
import PropTypes from 'prop-types';
import { useTransition } from 'react-spring';
import { GenericSplash } from '../molecules/Splash/GenericSplash';
import { AnimatedScreen } from '../atoms/containers/AnimatedScreen';

AnimatedSplashContainer.propTypes = {
  showSplash: PropTypes.bool.isRequired,
  splashComponent: PropTypes.node,
  zIndex: PropTypes.number,
  trail: PropTypes.number,
};

AnimatedSplashContainer.defaultProps = {
  showSplash: true,
  splashComponent: <GenericSplash />,
  zIndex: 9998,
  trail: 900,
};

export function AnimatedSplashContainer(props) {
  const { showSplash, splashComponent, zIndex } = props;

  const trans = useTransition(showSplash, null, {
    native: true,
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: props.trail,
    config: { duration: 500 },
  });

  return (
    <React.Fragment>
      {trans.map(({ item, key, props }) => {
        if (item) {
          return (
            <AnimatedScreen key={key} style={{ ...props, zIndex }} zIndex>
              {splashComponent}
            </AnimatedScreen>
          );
        }
        return null;
      })}
      {props.children}
    </React.Fragment>
  );
}
