import { combineReducers } from 'redux';
import depot from './depot';
import depots from './depots';
import vendor from './vendor';
import dailyOrders from './dailyOrders';

export default combineReducers({
  depot,
  depots,
  vendor,
  dailyOrders,
});
