import firebase from '../../services/firebase.service';
import * as AuthUser from '../../services/models/auth-user.model';
import { storeProfile, storeAuthUser } from './user.actions';
import { applicationError, initUser } from './app.actions';

export function watchAuthState() {
  return async (dispatch) => {
    return new Promise((resolve) => {
      AuthUser.onAuthStateChanged((firebaseAuthResult) => {
        if (firebaseAuthResult) {
          // User is signed in.
          const firebaseUser = firebaseAuthResult.toJSON();
          AuthUser.whoAmI()
            .then((user) => {
              // console.log('whoAmI user', user);
              dispatch(storeAuthUser(user));
              resolve(user);
            })
            .catch((error) => {
              // console.log('whoAmI error', error);
            });
        } else {
          // No user is signed in.
          resolve(null);
        }
      });
    });
  };
}

export function initializeOAuthListeners() {
  return (dispatch) => {
    firebase.auth().getRedirectResult()
      .then((result) => {
        if (result.user) {
          const user = result.user.toJSON();
          dispatch(storeAuthUser(user));
        }
      })
      .catch((error) => {
        console.warn('initializeOAuthListeners error', error);
      });
  };
}

export function performCreateAccount(email, password, displayName) {
  return async (dispatch) => {
    const result = await AuthUser.createAccount(email, password);
    await firebase.auth().currentUser.updateProfile({ displayName });
    await dispatch(initUser());
    return result;
  };
}

export function performLogin(email, password) {
  return async (dispatch) => {
    const res = await AuthUser.login(email, password);
    await dispatch(initUser());
  };
}

export function requestPasswordReset(email) {
  return async (dispatch) => {
    await firebase.auth().sendPasswordResetEmail(email);
    await dispatch(initUser());
  };
}

export function performGoogleLogin() {
  return async (dispatch) => {
    await AuthUser.loginWithGoogle();
    await dispatch(initUser());
  };
}

export function performFacebookLogin() {
  return async (dispatch) => {
    await AuthUser.loginWithFacebook();
    await dispatch(initUser());
  };
}

export function performLogout() {
  return (dispatch) => {
    return AuthUser.logout()
      .then(() => {
        // Sign-out successful.
        dispatch(storeAuthUser(null));
        dispatch(storeProfile({}));
      })
      .catch((error) => {
        // An error happened.
        dispatch(applicationError(error));
      });
  };
}
