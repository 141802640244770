import { combineReducers } from "redux";
import connectedDeliveryZone from "./customer/connectedDeliveryZone.reducer";
import connectedShift from "./customer/connectedShift.reducer";
import deliveryAddress from "./customer/deliveryAddress.reducer";
import customerPosition from "./customer/customerPosition.reducer";
import connectedVehicle from "./customer/connectedVehicle.reducer";
import estimatedDeliveryTime from "./customer/estimatedDeliveryTime.reducer";
import connectedVendor from "./customer/connectedVendor.reducer";
import lastOrder from "./customer/lastOrder.reducer";
import nearby from "./customer/nearby.reducer";
import firstRedirect from "./customer/firstRedirect.reducer";

export default combineReducers({
  deliveryAddress,
  customerPosition,
  connectedVehicle,
  connectedShift,
  estimatedDeliveryTime,
  connectedVendor,
  connectedDeliveryZone,
  lastOrder,
  nearby,
  firstRedirect,
});
