import { isValidDeliveryAddress } from '../../helpers/position.helper';
import { profilesAPI } from '../../services/api/profiles.apiService';
import {
  defaultDeliveryAddressSelector,
  deliveryAddressSelector, lastDeliveryAddressSelector,
} from '../selectors/customer/deliveryAddress.selector';
import { composedUserSelector } from '../selectors/user.selector';
import { fetchNearby } from './nearby.actions';
import { fetchProfile, saveProfileFieldsIfNotExisting } from './user.actions';
import * as types from './types';
import { reverseGeocode } from '../../services/maps.service';
import { storage } from '../../services/localStorage.service';

/**
 * Action Creator: for storing whether the customer GPS position is being requested
 *
 * @param {boolean} payload
 * @returns {{type, payload}}
 */
function setLocating(payload) {
  return {
    type: types.SET_LOCATING,
    payload,
  };
}

/**
 * Action Creator: for storing the customer GPS position
 *
 * @param {object} payload
 * @returns {{type, payload: object}}
 */
function storeCustomerPosition(payload) {
  return {
    type: types.SET_CUSTOMER_POSITION,
    payload,
  };
}

/**
 * Action Creator: for storing whether the customer delivery address is being requested
 *
 * @param {boolean} payload
 * @returns {{type, payload}}
 */
function loadingDeliveryAddress(payload) {
  return {
    type: types.LOADING_DELIVERY_ADDRESS,
    payload,
  };
}

/**
 * Action Creator: for storing the customer GPS position
 *
 * @param {object} payload
 * @returns {{type, payload: object}}
 */
function storeDeliveryAddress(payload) {
  return {
    type: types.SET_DELIVERY_ADDRESS,
    payload,
  };
}

/**
 * Action Creator: for storing the customer GPS position
 *
 * @param {object} payload
 * @returns {{type, payload: object}}
 */
export function updateDeliveryAddress(payload) {
  return (dispatch) => {
    // Save to local storage
    storage.setDeliveryAddress(payload);

    // Save to redux
    dispatch(storeDeliveryAddress(payload));

    // Refresh nearby
    dispatch(fetchNearby());
  };
}

/**
 * Async Action: Initializes Profile Delivery Location
 * Will use default delivery address if available, otherwise will use browser GPS API
 */
export function initializeDeliveryAddress() {
  return (dispatch, getState) => {
    dispatch(loadingDeliveryAddress(true));

    const selectedAddress = deliveryAddressSelector(getState());
    const lastDeliveryAddress = lastDeliveryAddressSelector(getState());
    const cachedDeliveryAddress = storage.getDeliveryAddress();
    const defaultAddress = defaultDeliveryAddressSelector(getState());

    if (isValidDeliveryAddress(selectedAddress)) {
      // If we already have a position, do nothing
      dispatch(loadingDeliveryAddress(false));
    } else if (isValidDeliveryAddress(cachedDeliveryAddress)) {
      // If there is no position, but we do have a cached address, use that
      dispatch(updateDeliveryAddress(cachedDeliveryAddress));
      dispatch(loadingDeliveryAddress(false));
    } else if (isValidDeliveryAddress(lastDeliveryAddress)) {
      // If there is no position, but we do have a last delivery address, use that
      dispatch(updateDeliveryAddress(lastDeliveryAddress));
      dispatch(loadingDeliveryAddress(false));
    } else if (isValidDeliveryAddress(defaultAddress)) {
      // If there is no position, but we do have a default address, use that
      dispatch(updateDeliveryAddress(defaultAddress));
      dispatch(loadingDeliveryAddress(false));
    } else {
      // If we have nothing, ask for their GPS location
      // dispatch(setDeliveryAddressFromGeoLocation());
      dispatch(setLocating(false));
      dispatch(loadingDeliveryAddress(false));
    }
  };
}

/**
 * Async Action: Requests the Customer GPS location from the browser API
 * and stores the Customer Position coordinates
 */
export function requestCustomerPosition() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLocating(true));

      const geoOptions = {
        enableHighAccuracy: true,
        // maximumAge: 300000,
        // timeout: timeout,
      };

      // only apply a timeout when we have a user with addresses or a delivery address
      // this is our best way of guessing whether they have granted geolocation permissions or not
      // We don't want to apply the timeout if they haven't granted permission, because it starts counting
      // down before the user has clicked "Allow"
      const user = composedUserSelector(getState());
      const deliveryAddress = deliveryAddressSelector(getState());
      if (isValidDeliveryAddress(deliveryAddress) || (user && user.addresses && user.addresses.length > 0)) {
        geoOptions.timeout = 10000;
      }

      const onSuccess = (getCurrentPositionResult) => {
        const { coords } = getCurrentPositionResult;
        const position = {
          lat: coords.latitude,
          lng: coords.longitude,
        };
        dispatch(storeCustomerPosition(position));
        resolve(position);
        dispatch(setLocating(false));
      };

      const onError = (error) => {
        console.warn('getCurrentPosition ERROR', error);
        reject(error);
        dispatch(setLocating(false));
      };

      navigator.geolocation.getCurrentPosition(onSuccess, onError, geoOptions);
    });
  };
}

/**
 * Async Action: Requests the Customer GPS location from the browser API
 * and stores the Customer Position coordinates and reverseGeo address info
 * as the selected delivery address
 */
export function setDeliveryAddressFromGeoLocation() {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingDeliveryAddress(true));
      const position = await dispatch(requestCustomerPosition());
      return dispatch(setDeliveryAddressForCoordinates(position.lat, position.lng));
    } catch (error) {
      dispatch(loadingDeliveryAddress(false));
      console.warn('setDeliveryAddressFromGeoLocation error', error);
    }
  };
}

/**
 *
 * Async Action: Fetches the reverseGeo address info for the given coordinates,
 * and selects it as the delivery address
 * @param lat
 * @param lng
 * @returns {function(*, *)}
 */
export function setDeliveryAddressForCoordinates(lat, lng) {
  return (dispatch) => {
    dispatch(loadingDeliveryAddress(true));

    return reverseGeocode(lat, lng)
      .then((result) => {
        const address = {
          ...result,
          lat,
          lng,
        };
        dispatch(updateDeliveryAddress(address));
        dispatch(loadingDeliveryAddress(false));
      })
      .catch((error) => {
        console.warn('setDeliveryAddressForCoordinates error', error);
        dispatch(loadingDeliveryAddress(false));
      });
  };
}

/**
 * [Async Action] Saves currently selected delivery address to the database
 *
 */
export function saveCustomerPositionToDB() {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingDeliveryAddress(true));

      const user = composedUserSelector(getState());
      const address = deliveryAddressSelector(getState());

      let updatedUser = null;
      if (user && user.id) {
        await profilesAPI.updateCustomerDeliveryAddress(user.id, address);
        await dispatch(saveProfileFieldsIfNotExisting(user));
        updatedUser = dispatch(fetchProfile());
      }

      dispatch(loadingDeliveryAddress(false));
      return updatedUser;
    } catch (error) {
      console.warn('saveCustomerPositionToDB error', error);
      dispatch(loadingDeliveryAddress(false));
    }
  };
}
