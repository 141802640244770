import * as types from '../../actions/types';

const initialStateData = [];

export default function (state = initialStateData, action) {
  switch (action.type) {
    case types.SET_CONNECTED_MENU_ID:
      return action.data;
    default:
      return state;
  }
}
