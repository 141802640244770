import muiThemeable from 'material-ui/styles/muiThemeable';
import React, { createContext, useContext } from 'react';

const ThemeContext = createContext({});

function Provider(props) {
  const value = props.muiTheme;
  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export const ThemeHookProvider = muiThemeable()(Provider);

export function useTheme() {
  return useContext(ThemeContext);
}

export function useThemePalettePrimaryMain() {
  const theme = useTheme();
  return theme.palette.primary1Color;
}

export function useThemePaletteSecondaryMain() {
  const theme = useTheme();
  return theme.palette.primary2Color;
}

export function useThemePaletteErrorMain() {
  const theme = useTheme();
  return theme.palette.error;
}
