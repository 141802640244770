import { combineReducers } from 'redux';
import * as types from '../actions/types';

const initialState = {
  loading: false,
  device: null,
  connection: null,
  logs: [],
  error: null,
  connected: false,
  isMuted: false,
  myVolume: 0.0,
  theirVolume: 0.0,
};

function loading(state = initialState.loading, action) {
  switch (action.type) {
    case types.LOADING_VOICE_CALL:
      return action.data;
    default:
      return state;
  }
}

function device(state = initialState.device, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_DEVICE: {
      return action.data;
    }
    default:
      return state;
  }
}

function connection(state = initialState.connection, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_CONNECTION: {
      return action.data;
    }
    default:
      return state;
  }
}

function logs(state = initialState.logs, action) {
  switch (action.type) {
    case types.RESET_VOICE_CALL_LOG: {
      return initialState.logs;
    }
    case types.ADD_VOICE_CALL_LOG: {
      return state.concat(action.data);
    }
    default:
      return state;
  }
}

function error(state = initialState.error, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_ERROR:
      return action.data;
    default:
      return state;
  }
}

function connected(state = initialState.connected, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_CONNECTED:
      return action.data;
    default:
      return state;
  }
}

function isMuted(state = initialState.isMuted, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_IS_MUTED:
      return action.data;
    default:
      return state;
  }
}

function myVolume(state = initialState.myVolume, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_VOLUME:
      return action.data.mine || initialState.myVolume;
    default:
      return state;
  }
}

function theirVolume(state = initialState.theirVolume, action) {
  switch (action.type) {
    case types.SET_VOICE_CALL_VOLUME:
      return action.data.theirs || initialState.theirVolume;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  device,
  connection,
  logs,
  error,
  connected,
  isMuted,
  myVolume,
  theirVolume,
});
