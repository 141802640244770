import { combineReducers } from 'redux';
import vehicle from './driver/driverVehicle.reducer';
import position from './driver/driverPosition.reducer';
import depot from './driver/driverDepot.reducer';
import vendor from './driver/driverVendor.reducer';
import orders from './driver/driverOrders.reducer';
import directions from './driver/driverDirections.reducer';
import vehiclePosition from './driver/vehiclePosition.reducer';
import deliveryZone from './driver/driverDeliveryZone.reducer';
import deliveryZones from './driver/driverDeliveryZones.reducer';
import flash from './driver/flash';
import modifiedOrder from './driver/modifiedOrder.reducer';
import shifts from './driver/driverShifts.reducer';

export default combineReducers({
  vehicle,
  position,
  depot,
  vendor,
  orders,
  directions,
  vehiclePosition,
  deliveryZone,
  deliveryZones,
  flash,
  modifiedOrder,
  shifts,
});
