import * as types from '../actions/types';

export default function config(state = {}, { type, config }) {
  switch (type) {
    case types.CONFIG_CHANGED:
      return { ...state, ...config };
    default:
      return state;
  }
}
