import React from 'react';
import ReactDOM from 'react-dom';
import { AppProviderWrapper } from './components/shared/AppProviderWrapper';
import { App } from './components/shared/App';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import './styles/index.css';

Sentry.init({
  dsn: 'https://007bc4c072ee4349bb34396a70014e25@o576458.ingest.sentry.io/5730030',
  release: `fleat-react@${process.env.REACT_APP_VERSION || 'local'}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
  environment: process.env.REACT_APP_FIREBASE_PROJECT_ID === 'fleat-9fcac' ? 'production' : 'development',
  normalizeDepth: 10,
  denyUrls: [
    /cdn\.userway\.org/i,
    /extensions\//i,
    /^chrome:\/\//i,
  ]
})

function mount(Component) {
  const SentryWrapped = Sentry.withProfiler(Component);
  ReactDOM.render(<SentryWrapped />, document.getElementById('root'));
}

mount(App);

if (module.hot) {
  module.hot.accept('./components/shared/App', () => {
    const NextApp = require('./components/shared/App').App;
    mount(NextApp);
  });
}

// Service worker registration
registerServiceWorker();
