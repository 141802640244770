import qs from 'qs';
import { fleatAPI } from '../api.service';

class PromosAPI {
  constructor() {
    this.TYPE = {
      PERCENT: 'PERCENT',
      AMOUNT: 'AMOUNT',
    };

    this.MINIMUM_TYPE = {
      NONE: 'NONE',
      AMOUNT: 'AMOUNT',
      QUANTITY: 'QUANTITY',
    };

    this.ELIGIBILITY = {
      ALL: 'ALL',
      GROUP: 'GROUP',
    };

    this.GROUP = {
      NEW: 'NEW',
      RETURNING: 'RETURNING',
      FLASH: 'FLASH',
    };
  }

  /**
   * This will fetch the currently available promos for the given shift that the currently logged in user is eligible for
   *
   * @param shiftID - the id of the shift to fetch available promos for
   * @returns {Promise<[Object]>} - promos
   */
  async fetchMyAvailablePromosForShift(shiftID) {
    return fleatAPI.callAPI({
      url: `/promos/shift/${shiftID}/me`,
    });
  }

  /**
   * This will fetch a specific promo by id
   *
   * @param promoID - the id of the promo to fetch
   * @returns {Promise<[Object]>} - promos
   */
  async fetchPromoByID(promoID) {
    return fleatAPI.callAPI({
      url: `/promos/id/${promoID}`,
    });
  }

}

export const promosAPI = new PromosAPI();
