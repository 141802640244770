import { combineReducers } from 'redux';
import active from './orders/activeOrders.reducer';
import closed from './orders/closedOrders.reducer';
import dailyTotal from './orders/dailyTotal.reducer';
import preorders from './orders/preorders.reducer';

export default combineReducers({
  active,
  closed,
  dailyTotal,
  preorders,
});
