import moment from "moment/moment";
import React from "react";
import { ordersFS } from "../services/models/orders.fs_model";
import { centsToDollars } from "./numberFormatter.helper";

export function calculateDailyTotalForVehicle(orders) {
  const orderTotalNum = orders
    .filter((order) => order.status === ordersFS.STATUS.COMPLETE)
    .reduce((acc, order) => {
      return acc + order.billDetails.discountedSubtotal;
    }, 0);
  return centsToDollars(orderTotalNum);
}

export function calculateTotalOrderQuantity(order) {
  let totalItems = 0;
  if (order && order.items) {
    order.items.forEach((item) => {
      totalItems += item.selectedQuantity;
    });
  }
  return totalItems;
}

export function calculateGroupBillDetails(group) {
  const groupBillDetails = {
    discountedSubtotal: 0,
    itemSubTotal: 0,
    deliveryFee: 0,
    discount: 0,
    discountAmount: 0,
    orderTotal: 0,
    taxAmount: 0,
  };
  group.forEach((order, index) => {
    groupBillDetails.discountedSubtotal += order.billDetails.discountedSubtotal;
    groupBillDetails.itemSubTotal += order.billDetails.itemSubTotal;
    groupBillDetails.deliveryFee += order.billDetails.deliveryFee;
    groupBillDetails.discount = order.billDetails.discount;
    groupBillDetails.discountAmount += order.billDetails.discountAmount;
    groupBillDetails.orderTotal += order.billDetails.orderTotal;
    groupBillDetails.taxAmount += order.billDetails.taxAmount;
  });
  return groupBillDetails;
}

export function calculateGroupTotalOrderQuantity(orders) {
  return orders.reduce((acc, order) => {
    return acc + calculateTotalOrderQuantity(order);
  }, 0);
}

export function isOrderBehindETA(order) {
  return order.originalEstimatedDeliveryTime < order.estimatedDeliveryTime;
}

export function calculateETA(order) {
  let value = isOrderBehindETA(order) ? "-" : "";
  value += moment(order.originalEstimatedDeliveryTime).from(
    order.estimatedDeliveryTime,
    true
  );
  return value;
}

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber && typeof phoneNumber === "string") {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
  return "";
}

export function sanitizeProductSelectionsForOrder(
  product,
  selectedQuantity,
  selectedOption,
  specialInstructions,
  selectedAddons,
  categoryName = ""
) {
  if (!product) {
    throw new Error("Product is required to add to cart");
  }
  if (!selectedQuantity) {
    throw new Error("Selected Quantity is required to add to cart");
  }
  if (!selectedOption) {
    throw new Error("Selected Option is required to add to cart");
  }
  console.log("sanitizeProduct", product, selectedOption, selectedAddons);
  const object = {
    id: product.id,
    displayName: product.displayName,
    categoryName,
    description: product.description,
    imageURLs: product.imageURLs,
    dietaryInformation: product.dietaryInformation,
    ingredients: product.ingredients,
    preparationInstructions: product.preparationInstructions,
    serviceTime: product.serviceTime,
    price: product.price,
    specialInstructions,
    selectedQuantity,
    denyTax: product.denyTax,
    denyDiscount: product.denyDiscount,
    selectedOption: sanitizePrimaryOptionForOrder(selectedOption),
    selectedAddons: sanitizeSelectedAddonsForOrder(selectedAddons),
  };
  console.log("normalized", object);
  return object;
}

export function sanitizePrimaryOptionForOrder(option) {
  return {
    id: option.id,
    type: option.type,
    displayName: option.displayName,
    description: option.description,
    price: option.price,
  };
}

export function sanitizeSelectedAddonsForOrder(addons) {
  return addons.map(sanitizeSelectedAddonForOrder);
}

export function sanitizeSelectedAddonForOrder(addon) {
  return {
    id: addon.id,
    type: addon.type,
    displayName: addon.displayName,
    description: addon.description,
    price: addon.price,
    selectedOptionParts: addon.selectedOptionParts.map(
      sanitizeSelectedAddonOptionPartForOrder
    ),
  };
}

export function sanitizeSelectedAddonOptionPartForOrder(optionPart) {
  return {
    id: optionPart.id,
    displayName: optionPart.part.displayName,
  };
}
