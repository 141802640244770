import * as types from '../actions/types';

export default function initialized(state = {}, action) {
  switch (action.type) {
    case types.SET_INITIALIZED:
      if (!action.item) {
        return state;
      }
      return {
        ...state,
        [action.item]: true,
      };
    default:
      return state;
  }
}
