import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initApplication } from '../../data/actions/app.actions';
import { connectedVendorSelector } from '../../data/selectors/customer/connectedVendor.selectors';
import { getIsAppInitFinished } from '../../data/selectors/initialized.selector';
import store from '../../data/store';
import { vendorsAPI } from '../../services/api/vendors.apiService';
import { GenericSplash } from './molecules/Splash/GenericSplash';
import { AnimatedSplashContainer } from './organisms/AnimatedSplashContainer';
import { AppRouter } from './routers/AppRouter';
import DevTools from './utility/DevTools';
import { AppProviderWrapper } from './AppProviderWrapper';

let devTools = <DevTools />;
if (process.env.NODE_ENV === 'production') {
  devTools = null;
}

function getVendorIDFromPath() {
  const parts = window.location.pathname.split('/');
  if (parts.length > 2) {
    if (parts[1] === 'store' || parts[1] === 'vendor') {
      return parts[2];
    }
  }
  return null;
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function App() {
  const [themeVendor, setThemeVendor] = useState(null);
  const vendorID = getVendorIDFromPath() || getParameterByName('vendorID');
  const hostname = window.location.hostname.toLowerCase();

  useEffect(() => {
    if (vendorID) {
      vendorsAPI.getVendorByID(vendorID)
        .then((vendor) => {
          setThemeVendor(vendor);
          store.dispatch(initApplication(vendor.id));
        })
        .catch((error) => {
        });
    } else {
      vendorsAPI.getVendorByHostname(hostname)
        .then((vendor) => {
          setThemeVendor(vendor);
          store.dispatch(initApplication(vendor.id));
        })
        .catch((error) => {
        });
    }
  }, [hostname, vendorID]);

  return (
    <AppProviderWrapper themeVendor={themeVendor}>
      <AppWithSplash />
      {devTools}
    </AppProviderWrapper>
  );
}

function AppWithSplash() {
  const isAppReady = useSelector(getIsAppInitFinished);
  const vendor = useSelector(connectedVendorSelector);
  return (
    <AnimatedSplashContainer showSplash={!isAppReady} splashComponent={<GenericSplash />} zIndex={9999} trail={300}>
      {/*
       ** Wait until we have a connected vendor before initializing the core app router,
       ** because it needs to known which vendor id to use in case redirects are needed
       */}
      {vendor && <AppRouter />}
    </AnimatedSplashContainer>
  );
}
