import { combineReducers } from 'redux';
import * as types from '../../actions/types';

const initialState = null;

function order(state = initialState, action) {
  switch (action.type) {
    case types.SET_LAST_ORDER:
      return action.data;
    default:
      return state;
  }
}

const initialStateData = null;

function vehicle(state = initialStateData, action) {
  switch (action.type) {
    case types.SET_LAST_ORDER_VEHICLE:
      return action.data;
    default:
      return state;
  }
}

function zone(state = initialStateData, action) {
  switch (action.type) {
    case types.SET_LAST_ORDER_ZONE:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  order,
  vehicle,
  zone,
});
