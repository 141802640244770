const {
  getBestResult,
  parseGoogleGeocodeResults,
  pointIsInPolygon,
} = require('../helpers/geo.helper');

// The below will tell eslint to ignore the google variable being undefined
/* global google */
const GeocodeService = new google.maps.Geocoder();
const DistanceMatrixService = new google.maps.DistanceMatrixService();
const DirectionsService = new google.maps.DirectionsService();

export const googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=
${process.env.REACT_APP_GOOGLE_MAPS_key}&v=3.exp&libraries=geometry,drawing,places`;

export function isPositionInDeliveryZone(address, deliveryZoneCoords) {
  // Convert DZ Coords to 2-D array
  const polygon = deliveryZoneCoords.map((p) => {
    return [p.lat, p.lng];
  });

  // Convert Address .lat & .lng fields to simple array
  const point = [address.lat, address.lng];

  return pointIsInPolygon(point, polygon);
}

export function geocode(address) {
  return new Promise((resolve, reject) => {
    GeocodeService
      .geocode({ address }, (response, status) => {
        const result = getBestResult(response);
        if (result) {
          resolve({
            address,
            location: {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            },
            status,
          });
        } else {
          reject(new Error('Address not found'));
        }
      });
  });
}

export const parseGoogleReverseGeocodeResults = parseGoogleGeocodeResults;

export function reverseGeocode(lat, lng) {
  return new Promise((resolve, reject) => {
    // const latlng = `${lat},${lng}`;
    const latlng = { lat, lng };
    const options = {
      location: latlng,
      // result_type: 'street_address',
    };
    GeocodeService
      .geocode(options, (response) => {
        const address = parseGoogleReverseGeocodeResults(response);
        resolve(address);
      });
  });
}

export function getTravelTime(origins, destinations) {
  return new Promise((resolve, reject) => {
    DistanceMatrixService
      .getDistanceMatrix({
        origins: [origins],
        destinations: [destinations],
        travelMode: 'DRIVING',
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      }, (response, status) => {
        try {
          // console.log('response.rows[0].elements[0].duration', response.rows[0].elements[0].duration);
          resolve({
            lastUpdated: Date.now(),
            seconds: response.rows[0].elements[0].duration.value,
            time: response.rows[0].elements[0].duration.text.replace(' mins', ''),
            distance: response.rows[0].elements[0].distance.text,
            status,
          });
        } catch (error) {
          resolve({
            lastUpdated: Date.now(),
            time: '??',
            distance: '??',
            status,
          });
        }
      });
  });
}

export function getDrivingRoute(origin, waypointCoords, destination) {
  return new Promise((resolve, reject) => {
    const waypoints = waypointCoords.map((coords) => {
      return {
        stopover: true,
        location: convertToLatLng(coords),
      };
    });
    DirectionsService.route({
      origin: convertToLatLng(origin),
      waypoints,
      destination: convertToLatLng(destination),
      optimizeWaypoints: false,
      travelMode: google.maps.TravelMode.DRIVING,
      avoidHighways: true,
      avoidTolls: true,
      avoidFerries: true,
    }, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        resolve(result);
      } else {
        reject(status);
      }
    });
  });
}

export function getStopsFromGoogleDirections(directions) {
  try {
    const legs = directions.routes[0].legs;
    const lastLeg = legs[legs.length - 1];

    const stops = legs.map(l => l.start_location);
    const lastStop = lastLeg.end_location;
    return stops.concat(lastStop);
  } catch (e) {
    return [];
  }
}

export function convertToLatLng(coordinates) {
  if (Array.isArray(coordinates)) {
    return new google.maps.LatLng(coordinates[0], coordinates[1]);
  }
  if (coordinates && Object.prototype.hasOwnProperty.call(coordinates, 'lat')) {
    if (typeof coordinates.lat === 'string' || typeof coordinates.lat === 'number') {
      return new google.maps.LatLng(coordinates.lat, coordinates.lng);
    }
  }
  return coordinates;
}

/**
 *
 * https://developers.google.com/chart/image/docs/gallery/dynamic_icons#plain_pin
 *
 * @param markerColor
 */
export function makeMarkerIcon(markerColor) {
  return {
    url: `/images/icons/map-pin-${markerColor}.png`,
    anchor: new google.maps.Point(13, 43),
    scaledSize: new google.maps.Size(27, 43),
    labelOrigin: new google.maps.Point(13, 14),
  };
}

export function getStreetViewImage(location, w, h) {
  return `https://maps.googleapis.com/maps/api/streetview`
    + `?size=${w}x${h}`         // Image size in pixels
    + `&fov=35`               // Field of View (expressed in degrees, smaller number "zooms")
    // + `&heading=235`       // Indicates the compass heading of the camera. Accepted values are from 0 to 360
    // + `&pitch=10`          // Specifies the up or down angle of the camera relative to the Street View vehicle
    + `&location=${location}` // address
    + `&key=${process.env.REACT_APP_GOOGLE_MAPS_key}`;
}

/**
 * Returns the distance in kilometers between two sets of lat,lng coordinates
 * adapted from https://stackoverflow.com/a/18883819
 *
 * @param lat1
 * @param lng1
 * @param lat2
 * @param lng2
 * @returns {number}
 */
export function latLngToKm(lat1, lng1, lat2, lng2) {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lng2 - lng1);
  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(lat2);

  const a = (Math.sin(dLat / 2) * Math.sin(dLat / 2)) +
    (Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

/**
 * Converts numeric degrees to radians
 *
 * @param value
 * @returns {number}
 */
function toRad(value) {
  return (value * Math.PI) / 180;
}
