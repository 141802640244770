import { combineReducers } from 'redux';
import menus from './inventory/menus.reducer';
import components from './inventory/components.reducer';
import menuItems from './inventory/menuItems.reducer';
import vehicleInventory from './inventory/vehicleInventory.reducer';
import activeMenuItems from './inventory/activeMenuItems.reducer';
import activeMenuComponents from './inventory/activeMenuComponents.reducer';
import connectedMenuID from './inventory/connectedMenuID.reducer';


export default combineReducers({
  menus,
  components,
  menuItems,
  vehicleInventory,
  activeMenuItems,
  activeMenuComponents,
  connectedMenuID,
});
