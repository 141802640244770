import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { defaultTheme } from './default.theme';

export function getVendorTheme(vendor) {
  return getMuiTheme({
    fontFamily: '"Roboto",Arial,"Helvetica Neue",Helvetica,sans-serif',
    palette: {
      primary1Color: vendor.colorPrimary || defaultTheme.palette.primary1Color,
      primary2Color: vendor.colorSecondary || defaultTheme.palette.primary2Color,
      pickerHeaderColor: vendor.colorPrimary || defaultTheme.palette.pickerHeaderColor,
      accent1Color: vendor.colorError || defaultTheme.palette.accent1Color,
      success: vendor.colorSuccess || defaultTheme.palette.success,
      error: vendor.colorError || defaultTheme.palette.error,
      info: vendor.colorInfo || defaultTheme.palette.info,
    },
    toolbar: {
      color: vendor.colorSecondaryText || defaultTheme.toolbar.color,
      backgroundColor: vendor.colorSecondary || defaultTheme.toolbar.backgroundColor,
    },
    menuItem: {
      selectedTextColor: vendor.colorPrimary || defaultTheme.menuItem.selectedTextColor,
    },
  });
}
