import { THIRTY_SECONDS } from '../../helpers/timeInterval.helper';
import { vehiclesAPI } from '../../services/api/vehicles.apiService';
import { vehiclesFS } from '../../services/models/vehicles.fs_model';
import * as types from './types';
import {
  driverDepotSelector,
  driverDirectionsLoadingSelector, driverPositionSelector,
  driverVehiclePositionSelector, driverVehicleSelector,
} from '../selectors/driver.selector';
import { driverActiveOrdersSelector, driverGroupedOrdersSelector } from '../selectors/orders.selector';
import { getDrivingRoute } from '../../services/maps.service';

function storeDriverPosition(data) {
  return {
    type: types.SET_DRIVER_POSITION,
    data,
  };
}

function storeDriverVehiclePosition(data) {
  return {
    type: types.SET_DRIVER_VEHICLE_POSITION,
    data,
  };
}

function storeDriverDirections(data) {
  return {
    type: types.SET_DRIVER_DIRECTIONS,
    data,
  };
}

/**
 * Async Action: Requests driving directions using the driver's vehicle's position, the order queue,
 * and the depot's address
 */
let lastUpdatedDirectionsTime = 0;

export function updateDrivingRoute() {
  return (dispatch, getState) => {
    const loading = driverDirectionsLoadingSelector(getState());
    const vehiclePosition = driverVehiclePositionSelector(getState());
    const groupedOrders = driverGroupedOrdersSelector(getState());
    const depot = driverDepotSelector(getState());

    if (!loading && vehiclePosition && groupedOrders && depot && depot.address) {
      // Only refresh driving directions if they have been updated in the last 30 seconds
      if (Date.now() - lastUpdatedDirectionsTime > THIRTY_SECONDS) {
        lastUpdatedDirectionsTime = Date.now();
        dispatch({ type: types.LOADING_DRIVER_DIRECTIONS, loading: true });

        // Convert order deliveryAddresses to waypoints coordinates
        const waypoints = [];
        Object.entries(groupedOrders).forEach(([deliveryQueueNumber, ordersInGroup]) => {
          waypoints[deliveryQueueNumber] = ordersInGroup[0].deliveryAddress.formatted;
        });

        getDrivingRoute(vehiclePosition, waypoints, depot.address)
          .then((directions) => {
            dispatch(storeDriverDirections(directions));
            dispatch({ type: types.LOADING_DRIVER_DIRECTIONS, loading: false });
          })
          .catch((error) => {
            console.error(`error fetching directions ${error}`);
            dispatch({ type: types.LOADING_DRIVER_DIRECTIONS, loading: false });
          });
      }
    }
  };
}

let driverPositionWatcher;

/**
 * Async Action: Requests live updates of the Driver's GPS location from the browser API
 * and stores the GPS Info
 */
export function watchDriverPosition() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({ type: types.LOADING_DRIVER_POSITION, loading: true });

      const geoOptions = {
        // enableHighAccuracy: true,
        // maximumAge: 300000,
        // timeout: timeout,
      };

      const onSuccess = (watchDriverPositionResult) => {
        const { coords } = watchDriverPositionResult;
        const position = {
          lat: coords.latitude,
          lng: coords.longitude,
        };
        dispatch(storeDriverPosition(position));
        resolve(position);

        dispatch(updateVehiclePosition());
        dispatch(updateDrivingRoute());
        dispatch({ type: types.LOADING_DRIVER_POSITION, loading: false });
      };

      const onError = (error) => {
        console.warn('watchDriverPosition error', error);
        dispatch({ type: types.LOADING_DRIVER_POSITION, loading: false });
      };

      // Check if we're currently watching, and if so, stop watching
      if (driverPositionWatcher) {
        navigator.geolocation.clearWatch(driverPositionWatcher);
        driverPositionWatcher = null;
        dispatch(storeDriverPosition(null));
        dispatch({ type: types.LOADING_DRIVER_POSITION, loading: false });
      } else {
        driverPositionWatcher = navigator.geolocation.watchPosition(onSuccess, onError, geoOptions);
        navigator.geolocation.getCurrentPosition(onSuccess, onError, geoOptions);
      }
    });
  };
}

function updateVehiclePosition() {
  return (dispatch, getState) => {
    const vehicle = driverVehicleSelector(getState());
    const driverPosition = driverPositionSelector(getState());
    if (vehicle && driverPosition) {
      dispatch(updateVehiclePositionToCoordinates(driverPosition));
    }
  };
}

export function updateVehiclePositionToCoordinates(coordinates) {
  return (dispatch, getState) => {
    const vehicle = driverVehicleSelector(getState());
    if (vehicle && coordinates) {
      vehiclesAPI.updateVehiclePosition(vehicle.id, coordinates)
        .catch((error) => {
          console.warn('updateVehiclePositionToCoordinates error', error);
        });
    }
  };
}

/**
 * Async Action: Requests live updates of the Driver's Vehicle location from firebase
 * and stores the GPS Info
 */
export function watchDriverVehiclePosition(vehicleID) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

      // TODO: Remove this simulated driver movement at some point
      // setInterval(() => {
      //   console.log('------------moving------------');
      //   const pos = driverPositionSelector(getState());
      //   console.log('pos', pos);
      //   dispatch(storeDriverPosition({
      //     lat: pos.lat + 0.0002,
      //     lng: pos.lng - 0.0002,
      //   }));
      // }, 2000);

      vehiclesFS.watchVehiclePosition(vehicleID)
        .listen((pos) => {
          dispatch(storeDriverVehiclePosition(pos));
          dispatch(updateDrivingRoute());
          resolve(pos);
        })
        .catch((error) => {
          console.warn('watchDriverVehiclePosition error', error);
          reject(error);
        });
    });
  };
}
