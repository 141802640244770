import Listener from '../../helpers/Listener';
import firebase from '../firebase.service';
import FirestoreSanitizer from './FirestoreSanitizer';

/**
 * Orders
 */
class OrdersFS {
  constructor() {
    this.sanitize = new FirestoreSanitizer(this.COLLECTION_PROPS);
  }

  // ***
  // Module Constants
  // ***

  /**
   * Firestore collection name
   *
   * @type {string}
   */
  COLLECTION_NAME = 'orders';

  /**
   * List of available properties for documents in this collections
   *
   * @type {string[]}
   */
  COLLECTION_PROPS = [
    'createdAt',
    'updatedAt',
    'deliveredAt',
    'deliverAfter',
    'firebaseUserID',
    'profileID',
    'vendorID',
    'depotID',
    'vehicleID',
    'shiftID',
    'isPreorder',
    'orderType',
    'includeCutlery',
    'estimatedDeliveryTime',
    'originalEstimatedDeliveryTime',
    'deliveryQueueNumber',
    'submittedBy',
    'submittedByDisplayName',
    'modifiedBy',
    'completedBy',
    'customerContact',
    'instructions',
    'deliveryAddress',
    'status',
    'items',
    'reOpened',
    'paymentType',
    'billDetails',
    'stripeChargeID',
    'stripeSource',
    'stripePaymentIntentID',
  ];

  STATUS = {
    // Waiting for Successful Charge Confirmation
    PENDING: 'PENDING',
    // Out for delivery
    OPEN: 'OPEN',
    // Delivery completed
    COMPLETE: 'COMPLETE',
    // Order Cancelled
    CANCELLED: 'CANCELLED',
  };

  TYPE = {
    // Delivery Order
    DELIVERY: 'DELIVERY',
    // Pickup Order
    STATIONARY: 'PICKUP',
  };

  PAYMENT_TYPE = {
    // Stored Payment Method (saved to customer profile)
    STRIPE_CUSTOMER_SOURCE: 'STRIPE_CUSTOMER_SOURCE',
    // Browser Payment (Apple Pay / Google Pay)
    STRIPE_BROWSER_PAY: 'STRIPE_BROWSER_PAY',
    // Card Swipe Payment (android card swipe reader)
    STRIPE_PAYMENT_INTENT: 'STRIPE_PAYMENT_INTENT',
  };

  subscriptions = {
    vendorOrdersForDate: () => null,
    depotOrdersForDate: () => null,
    lastOrder: () => null,
  };

  collectionRef = firebase.firestore().collection(this.COLLECTION_NAME);

  // ***
  // Module Exported Functions
  // ***

  watchVendorOrdersForDate(vendorID, fromDate, toDate) {
    return new Listener((onUpdate, onError) => {
      const ordersRef = this.collectionRef
        .where('vendorID', '==', vendorID)
        .where('originalEstimatedDeliveryTime', '>', fromDate)
        .where('originalEstimatedDeliveryTime', '<', toDate)
        .orderBy('originalEstimatedDeliveryTime', 'desc');

      // Cancel any existing firestore watchers
      this.subscriptions.vendorOrdersForDate();
      this.subscriptions.vendorOrdersForDate = ordersRef.onSnapshot((collectionSnapshot) => {
        onUpdate(this.sanitize.collectionSnapshot(collectionSnapshot));
      }, onError);
    });
  }

  watchDepotOrdersForDate(depotID, fromDate, toDate) {
    return new Listener((onUpdate, onError) => {
      const ordersRef = this.collectionRef
        .where('depotID', '==', depotID)
        .where('originalEstimatedDeliveryTime', '>', fromDate)
        .where('originalEstimatedDeliveryTime', '<', toDate)
        .orderBy('originalEstimatedDeliveryTime', 'desc');

      // Cancel any existing firestore watchers
      this.subscriptions.depotOrdersForDate();
      this.subscriptions.depotOrdersForDate = ordersRef.onSnapshot((collectionSnapshot) => {
        onUpdate(this.sanitize.collectionSnapshot(collectionSnapshot));
      }, onError);
    });
  }

  watchMyLastOrder(firebaseUserID) {
    return new Listener((onUpdate, onError) => {
      const lastOrderRef = this.collectionRef
        .where('firebaseUserID', '==', firebaseUserID)
        .orderBy('createdAt', 'desc')
        .limit(1);

      // Cancel any existing firestore watchers
      this.subscriptions.lastOrder();
      this.subscriptions.lastOrder = lastOrderRef.onSnapshot((snap) => {
        if (snap.size === 1) {
          const order = this.sanitize.collectionSnapshot(snap)[0];
          onUpdate(order);
        } else {
          onError('No orders found');
        }
      }, onError);
    });
  }

}

export const ordersFS = new OrdersFS();
