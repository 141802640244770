import { StorageService } from './StorageService';

export function hydrateStateWithLocalStorage(state = {}) {
  const newState = {};
  // for all items in state
  for (const key in state) {
    // if the key exists in localStorage
    if (state.hasOwnProperty(key)) {
      if (localStorage.hasOwnProperty(key)) {
        // get the key's value from localStorage
        let value = localStorage.getItem(key);

        // parse the localStorage string and setState
        try {
          value = JSON.parse(value);
          newState[key] = value;
        } catch (e) {
          // handle empty string
          newState[key] = value;
        }
      } else {
        newState[key] = state[key];
      }
    }
  }
  return newState;
}

const KEYS = {
  deliveryAddress: 'deliveryAddress',
  shoppingMode: 'shoppingMode',
};

const DEFAULTS = {
  shoppingMode: null,
};

const config = {
  provider: window.localStorage,
  PREFIX: 'fleat_',
  KEYS,
  DEFAULTS,
};

const storageService = new StorageService(config);

class LocalStorageService {
  constructor(storageService) {
    this.storageService = storageService;
  }

  getDeliveryAddress() {
    return this.storageService.read(KEYS.deliveryAddress);
  }

  setDeliveryAddress(deliveryAddress) {
    return this.storageService.write(KEYS.deliveryAddress, deliveryAddress);
  }

  removeDeliveryAddress() {
    return this.storageService.delete(KEYS.deliveryAddress);
  }
}

export const storage = new LocalStorageService(storageService);
