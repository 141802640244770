import firebase from '../firebase.service';
import FirestoreSanitizer from './FirestoreSanitizer';
import moment from 'moment';

const db = firebase.firestore();
const auth = firebase.auth();

class CartsModel {
  constructor() {
    this.sanitize = new FirestoreSanitizer(this.COLLECTION_PROPS);
  }

  // ***
  // Module Constants
  // ***

  /**
   * Firestore collection name
   *
   * @type {string}
   */
  COLLECTION_NAME = 'carts';

  /**
   * List of available properties for documents in this collections
   *
   * @type {string[]}
   */
  COLLECTION_PROPS = [
    'items',
    'lastUpdatedAt',
    'tipAmount',
  ];

  subscriptions = {};

  // ***
  // Module Exported Functions
  // ***


  /**
   *
   * @returns {Promise<any>}
   */
  async getMyCart() {
    // TODO: update this logic to not interact with firebase auth directly
    const userID = auth.currentUser.uid;
    return db.collection(this.COLLECTION_NAME)
      .doc(userID)
      .get()
      .then((snapshot) => {
        return this.sanitize.document(snapshot);
      });
  }

  async updateMyCart(cart) {
    const updatedCart = {
      ...cart,
      lastUpdatedAt: moment().toDate(),
    };
    // TODO: update this logic to not interact with firebase auth directly
    if (auth.currentUser && auth.currentUser.uid) {
      const userID = auth.currentUser.uid;
      await db
        .collection(this.COLLECTION_NAME)
        .doc(userID)
        .set(this.sanitize.data(updatedCart));
      return this.getMyCart();
    } else {
      throw new Error('You must be logged in to add items to your cart. Click the login button below to login or create an account.');
    }
  }
}

export const Carts = new CartsModel();
export default Carts;

