import { combineReducers } from 'redux';
import * as types from '../../actions/types';

const initialState = {
  loading: false,
  data: null,
  inventory: [],
};

function loading(state = initialState.loading, action) {
  switch (action.type) {
    case types.LOADING_CONNECTED_VEHICLE:
      return action.data;
    default:
      return state;
  }
}

function data(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_CONNECTED_VEHICLE:
      return action.data;
    case types.SET_CONNECTED_SHIFT:
      return null;
    default:
      return state;
  }
}

function inventory(state = initialState.inventory, action) {
  switch (action.type) {
    case types.SET_CONNECTED_VEHICLE_INVENTORY:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  data,
  inventory,
});
