import { combineReducers } from 'redux';
import * as types from '../../actions/types';

const initialStateLoading = false;

function loading(state = initialStateLoading, action) {
  switch (action.type) {
    case types.LOADING_DRIVER_DEPOT:
      return action.loading;
    default:
      return state;
  }
}

const initialStateData = null;

function data(state = initialStateData, action) {
  switch (action.type) {
    case types.SET_DRIVER_DEPOT:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  data,
});
