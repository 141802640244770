import axios from 'axios';
import firebase from './firebase.service';
// const queryString = require("query-string");

const urlsByProjectID = {
  'fleat-9fcac': 'https://streetlogic.net',
  'fleat-development': 'https://develop.streetlogic.net',
};

const functionsLocation = process.env.REACT_APP_FIREBASE_FUNCTIONS_LOCAL_OVERRIDE || urlsByProjectID[process.env.REACT_APP_FIREBASE_PROJECT_ID];


class API {
  constructor() {
    this.requester = axios.create({
      baseURL: `${functionsLocation}/api/v1/`,
      responseType: 'json',
    });

    this.blobRequester = axios.create({
      baseURL: `${functionsLocation}/api/v1/`,
      responseType: 'blob',
    });
  }

  getAuthToken = () => {
    if (firebase.auth().currentUser) {
      return firebase
        .auth()
        .currentUser
        .getIdToken();
    }
    return Promise.resolve();
  };

  callAPI(opts, type) {
    return this.getAuthToken()
      .then((token) => {
        // Initialize Request Options & attach User's Auth Token to headers
        const defaultOpts = {
          mode: 'cors',
          headers: {
            'x-access-token': token,
          },
        };

        // Merge this requests options with the defaults
        const options = { ...defaultOpts, ...opts };

        // Execute the request
        if (type === 'blob') {
          return this.blobRequester(options);
        }
        return this.requester(options);
      })
      .then((response) => {
        // Un-wrap the axios response to return on the body or "data"
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        if (error.message) {
          return Promise.reject(error.message);
        }
        return Promise.reject(error);
      });
  }

  geocode(address) {
    return this.callAPI({
      url: `maps/geocode?address=${address}`,
    });
  }

  reverseGeocode(lat, lng) {
    return this.callAPI({
      url: `maps/revgeocode?lat=${lat}&lng=${lng}`,
    });
  }

  getDeliveryETA(vehicleID, deliveryAddress) {
    return this.callAPI({
      url: `queue/eta-calculation?vehicleID=${vehicleID}&lat=${deliveryAddress.lat}&lng=${deliveryAddress.lng}&formatted=${deliveryAddress.formatted}`,
    });
  }

  getQueue(vehicleID) {
    return this.callAPI({
      url: `queue/${vehicleID}`,
    });
  }

}


export default new API();
export const fleatAPI = new API();
