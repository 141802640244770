import { combineReducers } from 'redux';
import * as types from '../../actions/types';

const initialStateLoading = false;

function loading(state = initialStateLoading, action) {
  switch (action.type) {
    case types.LOADING_DELIVERY_ADDRESS:
      return action.payload;
    default:
      return state;
  }
}

const initialStateData = null;

function data(state = initialStateData, action) {
  switch (action.type) {
    case types.SET_DELIVERY_ADDRESS:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  data,
});
