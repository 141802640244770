import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const authUserSelector = (state) => {
  return _get(state, 'user.data', null);
};

export const profileLoadingSelector = (state) => {
  return _get(state, 'profile.loading', false);
};

export const profileSelector = (state) => {
  return _get(state, 'profile.data', null);
};

export const composedUserSelector = createSelector(
  authUserSelector,
  profileSelector,
  (user, profile) => {
    if (user) {
      return {
        ...user,
        ...profile,
      };
    }
    return null;
  },
);
