import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const driverDepotLoadingSelector = (state) => {
  return _get(state, 'driver.depot.loading', null);
};

export const driverDepotSelector = (state) => {
  return _get(state, 'driver.depot.data', null);
};

export const driverDirectionsLoadingSelector = (state) => {
  return _get(state, 'driver.directions.loading', null);
};

export const driverDirectionsSelector = (state) => {
  return _get(state, 'driver.directions.data', null);
};

export const driverPositionLoadingSelector = (state) => {
  return _get(state, 'driver.position.loading', null);
};

export const driverPositionSelector = (state) => {
  return _get(state, 'driver.position.data', null);
  // TODO: remove the testing stuff below
  // return this manually & change lat/long positions as needed
  //console.log('driverPositionSelector():');
  //console.log('_get(state, \'driver.position.data\', null)', _get(state, 'driver.position.data', null));
  // const position = { lat: 28.5683502, lng: -81.3437675 };
  // const position = { lat: 28.523954967990672, lng: -81.35582700462764 };
  // return state.driver.position.data || position;
  // return position;
};

export const driverVehicleLoadingSelector = (state) => {
  return _get(state, 'driver.vehicle.loading', null);
};

export const driverVehicleSelector = (state) => {
  return _get(state, 'driver.vehicle.data', null);
};

export const driverDailyTotalSelector = (state) => {
  return _get(state, 'driver.orders.dailyTotal.data', null);
};


export const driverVendorLoadingSelector = (state) => {
  return _get(state, 'driver.vendor.loading', null);
};

export const driverVendorSelector = (state) => {
  return _get(state, 'driver.vendor.data', null);
};

export const driverDeliveryZoneLoadingSelector = (state) => {
  return _get(state, 'driver.deliveryZone.loading', null);
};

export const driverDeliveryZoneSelector = (state) => {
  return _get(state, 'driver.deliveryZone.data', null);
};

export const driverDeliveryZonesSelector = (state) => {
  return _get(state, 'driver.deliveryZones.data', null);
};

export const driverActiveZonesSelector = createSelector(
  driverVehicleSelector,
  driverDeliveryZonesSelector,
  (vehicle, zones) => {
    return zones.find(z => z.id === vehicle.deliveryZoneID);
  },
);

export const driverVehiclePositionLoadingSelector = (state) => {
  return _get(state, 'driver.vehiclePosition.loading', null);
};

export const driverVehiclePositionSelector = (state) => {
  return _get(state, 'driver.vehiclePosition.data', null);
};

export const driverModifiedOrderSelector = (state) => {
  return _get(state, 'driver.modifiedOrder.data', null);
};
