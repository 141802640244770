import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const configsByProjectID = {
  'fleat-9fcac': {
    apiKey: 'AIzaSyC2-7g5e58KjYrBvNNmvh95idBw2G-JoOE',
    authDomain: 'fleat-9fcac.firebaseapp.com',
    databaseURL: 'https://fleat-9fcac.firebaseio.com',
    projectId: 'fleat-9fcac',
    storageBucket: 'fleat-9fcac.appspot.com',
    messagingSenderId: '202862152284',
  },
  'fleat-development': {
    apiKey: 'AIzaSyBEmclJI62mXm8CU2vLsuUeywm3c0tLyg8',
    authDomain: 'fleat-development.firebaseapp.com',
    databaseURL: 'https://fleat-development.firebaseio.com',
    projectId: 'fleat-development',
    storageBucket: 'fleat-development.appspot.com',
    messagingSenderId: '758029249026',
  },
  'ci': {
    apiKey: 'ci_test',
    authDomain: 'ci_test',
    databaseURL: 'ci_test',
    projectId: 'ci_test',
    storageBucket: 'ci_test',
    messagingSenderId: 'ci_test',
  },
};

export const config = configsByProjectID[process.env.REACT_APP_FIREBASE_PROJECT_ID];
firebase.initializeApp(config);

export default firebase;
