import _pick from 'lodash/pick';
import firebase from '../firebase.service';
import { fleatAPI } from '../api.service';

// ***
// Module Constants
// ***

/**
 * List of available properties for AuthUser objects
 *
 * @type {string[]}
 */
export const AUTH_USER_PROPS = [
  'uid',
  'displayName',
  'email',
  'emailVerified',
  'isAnonymous',
  'metadata',
  'photoURL',
  'providerData',
  'providerId',
];

function sanitize(data) {
  return _pick(data, AUTH_USER_PROPS);
}

async function handleDifferentCredentials(error) {
  // The pending auth credential.
  const pendingCred = error.credential;

  // The provider account's email address.
  const email = error.email;

  // Get existing sign-in methods for this email.
  const methods = await firebase.auth().fetchSignInMethodsForEmail(email);

  // If the user has several sign-in methods,
  // the first method in the list will be the "recommended" method to use.
  const method = methods[0];

  // Save the pending credential to session storage so it can be retrieved and merged with later
  window.sessionStorage.setItem('fleat_pendingCred', JSON.stringify(pendingCred));

  const customizedError = { ...error, method };
  if (method === 'password') {
    customizedError.message = error.message.replace(
      'Sign in using a provider associated with this email address',
      'Sign in using email and password to continue',
    );
  }
  if (method === 'google.com') {
    customizedError.message = error.message.replace(
      'Sign in using a provider associated with this email address',
      'Sign in using Google to continue',
    );
  }
  if (method === 'facebook.com') {
    customizedError.message = error.message.replace(
      'Sign in using a provider associated with this email address',
      'Sign in using Facebook to continue',
    );
  }
  throw customizedError;
}

export async function joinCredentials() {
  const currentUser = firebase.auth().currentUser;
  const pendingCredSerialized = window.sessionStorage.getItem('fleat_pendingCred');
  if (currentUser && pendingCredSerialized) {
    const pendingCred = JSON.parse(pendingCredSerialized);
    if (pendingCred) {
      const token = firebase.auth.FacebookAuthProvider.credential(pendingCred);
      await currentUser.linkWithCredential(token);
      // Additional account successfully linked to the existing Firebase user.
      // Remove the pending credential
      window.sessionStorage.removeItem('fleat_pendingCred');
    }
  }
}

// ***
// Module Exported Functions
// ***

export function createAccount(email, password) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export async function login(email, password) {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    // If the User's email already exists from a different auth provider
    if (error.code === 'auth/account-exists-with-different-credential') {
      // TODO: maybe enable this again later, but potentially dangerous in scenario where account exists via GOOGLE login
      // TODO: and then a different person creates a new account using the same email, with a new password
      // return handleDifferentCredentials(error);
    } else {
      throw error;
    }
  }
}

//https://firebase.google.com/docs/reference/js/firebase.auth.GoogleAuthProvider
export async function loginWithGoogle() {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    provider.addScope('profile');
    return await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    // If the User's email already exists from a different auth provider
    if (error.code === 'auth/account-exists-with-different-credential') {
      return handleDifferentCredentials(error);
    } else {
      throw error;
    }
  }
}

export async function loginWithFacebook() {
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    return await firebase.auth().signInWithPopup(provider);
  } catch (error) {
    // If the User's email already exists from a different auth provider
    if (error.code === 'auth/account-exists-with-different-credential') {
      return handleDifferentCredentials(error);
    } else {
      throw error;
    }
  }
}

export function unlinkFacebook() {
  const user = firebase.auth().currentUser;
  return user.unlink(firebase.auth.FacebookAuthProvider.PROVIDER_ID);
}

export function logout() {
  return firebase.auth().signOut();
}

export function onAuthStateChanged(callback) {
  firebase.auth().onAuthStateChanged(callback);
}

export function getCurrentAuthUser() {
  return sanitize(firebase.auth().currentUser);
}

export function updateAuthUser(data) {
  const profile = sanitize(data);
  return firebase.auth().currentUser.updateProfile(profile);
}

export async function whoAmI() {
  const user = await fleatAPI.callAPI({
    url: `auth/me`,
    method: 'GET',
  });
  return {
    ...user,
    firebaseUser: firebase.auth().currentUser.toJSON(),
  };
}
