import React from 'react';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
};

/**
 * Reference: https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error};
  }

  state = {
    hasError: false,
    error: null, 
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log the error to an error reporting service
    // console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <h1>Something went wrong.</h1>
          <div>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              <details style={{ whiteSpace: 'pre-wrap' }}>
                <summary>Stack Trace</summary>
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </details>
            </details>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
