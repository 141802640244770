import React from 'react';
import PropTypes from 'prop-types';

FullHeightContainer.propTypes = {
  background: PropTypes.string,
  display: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
  overflow: PropTypes.string,
  style: PropTypes.object,
};

FullHeightContainer.defaultProps = {
  background: 'rgba(242,242,242,1)',
  display: 'block',
  justify: 'flex-start',
  align: 'flex-start',
  overflow: 'auto',
  style: {},
};

export function FullHeightContainer(props) {
  const { background, display, justify, align, overflow, style } = props;
  const combinedStyles = {
    ...styles.fullHeight,
    background,
    display,
    justifyContent: justify,
    alignItems: align,
    overflow,
    ...style,
  };
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <div style={combinedStyles}>
      {props.children}
    </div>
  );
}

const styles = {
  fullHeight: {
    height: 'calc(var(--vh, 1vh) * 100)',
  },
};
