import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as Sentry from '@sentry/react';
// import { createLogger } from 'redux-logger';

import rootReducer from './root.reducer';
import DevTools from '../components/shared/utility/DevTools';

// setup middleware
let enhancer = compose(applyMiddleware(thunkMiddleware));
enhancer = compose(enhancer, Sentry.createReduxEnhancer());

if (process.env.NODE_ENV !== 'production') {
  // Enable Redux DevTools with the monitors you chose
  enhancer = compose(enhancer, DevTools.instrument());
}

export default createStore(rootReducer, enhancer);
