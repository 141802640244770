import _get from 'lodash/get';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { Carts } from '../../services/models/carts.model';
import { ordersAPI } from '../../services/api/orders.apiService';
import * as types from '../actions/types';
import { connectedShiftSelector } from '../selectors/customer/connectedShift.selectors';
import { connectedVehicleSelector } from '../selectors/customer/connectedVehicle.selectors';
import { connectedVendorSelector } from '../selectors/customer/connectedVendor.selectors';
import { connectedPromoSelector } from './connectedPromo';


// ------------------------------------
// REDUCER
// ------------------------------------
const initialState = {
  data: {
    items: [],
    tipAmount: 0,
    billDetails: {},
    isGuest: true,
  },
  loading: {
    isLoadingCart: true,
    isLoadingBillDetails: true,
  },
};

function data(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_CART:
      return {
        ...state,
        ...action.val,
      };
    case types.SET_CART_BILL_DETAILS:
      return {
        ...state,
        billDetails: action.val,
      };
    case types.CLEAR_CART:
      return initialState.data;
    default:
      return state;
  }
}

function loading(state = initialState.loading, action) {
  switch (action.type) {
    case types.SET_CART_IS_LOADING:
      return {
        ...state,
        isLoadingCart: action.val,
      };
    case types.SET_CART_BILL_DETAILS_IS_LOADING:
      return {
        ...state,
        isLoadingBillDetails: action.val,
      };
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  data,
});


// ------------------------------------
// SELECTORS
// ------------------------------------
const cartSelector = (state) => {
  return _get(state, 'customerApp.cart.data', {});
};
const cartLoadingSelector = (state) => {
  return _get(state, 'customerApp.cart.loading.isLoadingCart', {});
};
const cartBillDetailsLoadingSelector = (state) => {
  return _get(state, 'customerApp.cart.loading.isLoadingBillDetails', {});
};
export const composedCartSelector = createSelector(
  cartSelector,
  connectedVendorSelector,
  connectedVehicleSelector,
  connectedShiftSelector,
  connectedPromoSelector,
  (cart, vendor, vehicle, shift, promo) => {
    const totalItems = cart.items.reduce((acc, item) => {
      return acc + item.selectedQuantity;
    }, 0);

    return {
      ...cart,
      promoID: promo ? promo.id : null,
      totalItems,
    };
  },
);
export const composedCartLoadingSelector = createSelector(
  cartLoadingSelector,
  cartBillDetailsLoadingSelector,
  (isLoadingCart, isLoadingBillDetails) => {
    return isLoadingCart || isLoadingBillDetails;
  },
);


// ------------------------------------
// ACTIONS
// ------------------------------------
function setCart(val) {
  return { type: types.SET_CART, val };
}

function setCartBillDetails(val) {
  return { type: types.SET_CART_BILL_DETAILS, val };
}

export function setCartIsLoading(val) {
  return { type: types.SET_CART_IS_LOADING, val };
}

export function setCartBillDetailsIsLoading(val) {
  return { type: types.SET_CART_BILL_DETAILS_IS_LOADING, val };
}

export function clearCart() {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_CART });
    const cart = cartSelector(getState());
    dispatch(saveCart(cart));
  };
}

export function guestCartReady() {
  return (dispatch, getState) => {
    dispatch(setCartIsLoading(false));
    dispatch(setCartBillDetailsIsLoading(false));
  }
}

export function fetchCart() {
  return async (dispatch) => {
    dispatch(setCartIsLoading(true));
    const data = await Carts.getMyCart();
    dispatch(setCart({...data, isGuest: false}));
    await dispatch(refreshCartBillDetails());
    dispatch(setCartIsLoading(false));
    return data;
  };
}

export function refreshCartBillDetails() {
  return async (dispatch, getState) => {
    try {
      dispatch(setCartBillDetailsIsLoading(true));
      const cart = cartSelector(getState());
      const vehicle = connectedVehicleSelector(getState());
      const shift = connectedShiftSelector(getState());
      const promo = connectedPromoSelector(getState());
      if (vehicle || shift) {
        const data = await ordersAPI.getEstimatedBillDetails({
          items: cart.items,
          tipAmount: cart.tipAmount,
          vehicleID: vehicle ? vehicle.id : '',
          shiftID: shift ? shift.id : '',
          promoID: promo ? promo.id : '',
        });
        dispatch(setCartBillDetails(data));
        dispatch(setCartBillDetailsIsLoading(false));
        return data;
      }
      dispatch(setCartBillDetailsIsLoading(false));
      return {};
    } catch (error) {
      dispatch(setCartBillDetailsIsLoading(false));
      throw error;
    }
  };
}

export function saveCart(cart) {
  return async (dispatch) => {
    try {
      dispatch(setCartIsLoading(true));
      const data = await Carts.updateMyCart(cart);
      dispatch(setCart(data));
      await dispatch(refreshCartBillDetails());
      dispatch(setCartIsLoading(false));
      return data;
    } catch (error) {
      dispatch(setCartIsLoading(false));
      throw error;
    }
  };
}

export function setTipAmount(tipAmount) {
  return (dispatch, getState) => {
    const oldCart = composedCartSelector(getState());
    const cart = {
      ...oldCart,
      tipAmount,
    };
    return dispatch(saveCart(cart));
  };
}

export function addItemToCart(item) {
  return (dispatch, getState) => {
    const oldCart = composedCartSelector(getState());
    const items = oldCart.items.concat([item]);
    const cart = {
      ...oldCart,
      items,
    };
    return dispatch(saveCart(cart));
  };
}

export function removeItemFromCart(item) {
  return (dispatch, getState) => {
    const oldCart = composedCartSelector(getState());
    const items = oldCart.items.filter(i => i !== item);
    const cart = {
      ...oldCart,
      items,
    };
    return dispatch(saveCart(cart));
  };
}
