import Nearby from '../../services/api/shifts.apiService';
import { deliveryAddressSelector } from '../selectors/customer/deliveryAddress.selector';
import { connectedVendorSelector } from '../selectors/customer/connectedVendor.selectors';
import * as types from './types';

export function loadingNearby(data) {
  return {
    type: types.LOADING_NEARBY,
    data,
  };
}

export function storeNearby(data) {
  return {
    type: types.SET_NEARBY,
    data,
  };
}

export function fetchNearby() {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingNearby(true));
      dispatch(storeNearby(null));

      const vendor = connectedVendorSelector(getState());
      const deliveryAddress = deliveryAddressSelector(getState());
      if (deliveryAddress) {
        const nearby = await Nearby.getNearbyShifts(vendor.id, deliveryAddress.lat, deliveryAddress.lng);
        if (nearby) {
          dispatch(storeNearby(nearby));
        }
      }
      dispatch(loadingNearby(false));
    } catch (error) {
      dispatch(loadingNearby(false));
      console.warn('fetchNearby error', error);
    }
  };
}
