import _get from 'lodash/get';

export const driverActiveOrdersSelector = (state) => {
  return _get(state, 'driver.orders.active.data', []);
};

export const shiftPreordersSelector = (state) => {
  return _get(state, 'driver.orders.preorders.data', []);
};

export const shiftSentPreorderRemindersSelector = (state) => {
  return _get(state, 'driver.orders.preorders.sentReminders', []);
};

export const shiftReminderEligiblePreordersSelector = (state) => {
  return _get(state, 'driver.orders.preorders.reminderEligible', []);
};

export const driverGroupedOrdersSelector = (state) => {
  const activeOrders = _get(state, 'driver.orders.active.data', []);
  return activeOrders.reduce((acc, order) => {
    if (Number.isInteger(order.deliveryQueueNumber)) {
      acc[order.deliveryQueueNumber] = acc[order.deliveryQueueNumber] || [];
      acc[order.deliveryQueueNumber].push(order);
    } else {
      acc[order.id] = [order];
    }
    return acc;
  }, {});
};

export const composedDriverPastOrdersSelector = (state) => {
  return _get(state, 'driver.orders.closed.data', []);
};
