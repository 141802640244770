export class StorageService {
  constructor(config) {
    if (!config.provider) {
      throw new Error('config.provider is required to create a new StorageService');
    }
    if (!config.PREFIX) {
      throw new Error('config.PREFIX is required to create a new StorageService');
    }
    if (!config.KEYS) {
      throw new Error('config.KEYS is required to create a new StorageService');
    }
    if (!config.DEFAULTS) {
      throw new Error('config.DEFAULTS is required to create a new StorageService');
    }
    this.provider = config.provider;
    this.PREFIX = config.PREFIX;
    this.KEYS = config.KEYS;
    this.DEFAULTS = config.DEFAULTS;
  }

  isValidKey(key) {
    return !!Object.keys(this.KEYS).find(name => this.KEYS[name] === key);
  }

  read(key) {
    if (!key) {
      throw new Error('key is required');
    }
    if (!this.isValidKey(key)) {
      throw new Error(`${key} is not a valid storage key`);
    }
    const raw = this.provider.getItem(this.PREFIX + key);
    try {
      return JSON.parse(raw);
    } catch (err) {
      return raw || this.DEFAULTS[key];
    }
  }

  write(key, val) {
    if (!key) {
      throw new Error('key is required');
    }
    if (!this.isValidKey(key)) {
      throw new Error(`${key} is not a valid storage key`);
    }
    try {
      let raw = val;
      if (typeof val === 'object') {
        raw = JSON.stringify(val);
      }
      return this.provider.setItem(this.PREFIX + key, raw);
    } catch (err) {
      return this.provider.setItem(this.PREFIX + key, val);
    }
  }

  delete(key) {
    if (!key) {
      throw new Error('key is required');
    }
    if (!this.isValidKey(key)) {
      throw new Error(`${key} is not a valid storage key`);
    }
    try {
      return this.provider.removeItem(this.PREFIX + key);
    } catch (err) {
      return this.provider.removeItem(this.PREFIX + key);
    }
  }

  hydrateStateWithLocalStorage(state = {}) {
    const newState = {};
    // for all items in state
    for (const key in state) {
      // if the key exists in storage
      if (state.hasOwnProperty(key)) {
        if (localStorage.hasOwnProperty(key)) {
          // get the key's value from storage
          newState[key] = this.read(key);
        } else {
          newState[key] = state[key];
        }
      }
    }
    return newState;
  }
}
