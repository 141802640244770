import { calculateDailyTotalForVehicle } from '../../helpers/order.helper';
import { ordersAPI } from '../../services/api/orders.apiService';
import { OrderNotifications } from '../../services/models/order-notifications.model';
import { shiftsAPI } from '../../services/api/shifts.apiService';
import { updateDrivingRoute } from './driverPosition.actions';
import * as types from './types';

export function storeRelevantShift(data) {
  return {
    type: types.SET_RELEVANT_SHIFT,
    data,
  };
}

function storeShiftSentPreorderReminders(data) {
  return {
    type: types.SET_SHIFT_SENT_PREORDER_REMINDERS,
    data,
  };
}

function storeShiftReminderEligiblePreorders(data) {
  return {
    type: types.SET_SHIFT_REMINDER_ELIGIBLE_PREORDERS,
    data,
  };
}

function storeDriverDailyTotal(data) {
  return {
    type: types.SET_DRIVER_DAILY_TOTAL,
    data,
  };
}

function storeDriverActiveOrders(data) {
  return {
    type: types.SET_DRIVER_ACTIVE_ORDERS,
    data,
  };
}

function storeDriverClosedOrders(data) {
  return {
    type: types.SET_DRIVER_CLOSED_ORDERS,
    data,
  };
}

function storeShiftPreorders(data) {
  return {
    type: types.SET_SHIFT_PREORDERS,
    data,
  };
}

export function fetchOrdersForShift(shiftID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      ordersAPI.fetchOrdersForShift(shiftID)
        .then((orders) => {
          dispatch(storeDriverActiveOrders(orders.active));
          dispatch(storeDriverClosedOrders(orders.closed));
          dispatch(storeShiftPreorders(orders.upcoming));

          dispatch(storeDriverDailyTotal(calculateDailyTotalForVehicle(orders.closed)));

          dispatch(updateDrivingRoute());
          resolve(orders);
        })
        .catch((error) => {
          console.log('fetchOrdersForShift error', error);
          reject(error);
        });
    });
  };
}

/**
 * Fetch the currently active shift (LOADING or ACTIVE) or, the soonest upcoming shift (PUBLISHED) for a vehicle
 *
 * @param vehicleID
 * @return {Function}
 */
export function fetchRelevantShiftForVehicle(vehicleID) {
  return async (dispatch) => {
    try {
      const shift = await shiftsAPI.getRelevantShiftForVehicle(vehicleID);
      if (shift) {
        dispatch(storeRelevantShift(shift));

        const sentReminders = await OrderNotifications.getPreorderReminderNotifications(shift.id);
        dispatch(storeShiftSentPreorderReminders(sentReminders));

        const reminderEligiblePreorders = await ordersAPI.fetchPreordersEligibleForRemindersForShift(shift.id);
        dispatch(storeShiftReminderEligiblePreorders(reminderEligiblePreorders));
      } else {
        dispatch(storeRelevantShift(null));
      }
    } catch (error) {
      dispatch(storeRelevantShift(null));
      console.warn(error);
    }
  };
}
