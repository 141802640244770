import { fleatAPI } from '../api.service';

class VendorsAPI {
  /**
   * Fetches a list of all vendors (Fleat Admins only)
   *
   * @returns {Promise<Array<Object>>} - array of vendor object
   */
  getAll = async () => {
    return await fleatAPI.callAPI({
      method: 'GET',
      url: `/vendors`,
    });
  };

  /**
   * Fetches vendor data by domainName
   *
   * @param hostname - the hostname (domain name) used to fetch the associated vendor
   * @returns {Promise<Object>} - vendor object
   */
  getVendorByHostname = async (hostname) => {
    return await fleatAPI.callAPI({
      method: 'GET',
      url: `/vendors/hostname/${hostname}`,
    });
  };

  /**
   * Fetches vendor data by ID
   *
   * @param vendorID - the id of the vendor
   * @returns {Promise<Object>} - vendor object
   */
  getVendorByID = async (vendorID) => {
    return await fleatAPI.callAPI({
      method: 'GET',
      url: `/vendors/${vendorID}`,
    });
  };

  /**
   * Creates a new vendor
   *
   * @param payload - vendor data
   * @returns {Promise<Object>} - vendor object
   */
  createVendor = async (payload) => {
    return await fleatAPI.callAPI({
      method: 'POST',
      url: `/vendors`,
      data: payload,
    });
  };

  /**
   * Updates a vendor
   *
   * @param vendorID - the id of the vendor
   * @param payload - vendor data
   * @returns {Promise<Object>} - vendor object
   */
  updateVendor = async (vendorID, payload) => {
    return await fleatAPI.callAPI({
      method: 'PATCH',
      url: `/vendors/${vendorID}`,
      data: payload,
    });
  };
}

export const vendorsAPI = new VendorsAPI();
