import _findIndex from 'lodash/findIndex';
import * as types from '../actions/types';

export default function deliveryVehiclePositions(state = [], action) {
  const {
    type, key, coords, distance, time,
  } = action;
  switch (type) {
    case types.ADD_DELIVERY_VEHICLE_POSITION: {
      const index = _findIndex(state, { key });
      if (index < 0) {
        // insert
        const newArray = state.slice();
        const postion = 0;
        newArray.splice(postion, 0, { key, coords, distance });
        return newArray;
      }
      // modify
      return state.map((item) => {
        if (item.key === key) {
          return { key, coords, distance };
        }
        return item;
      });
    }
    case types.UPDATE_DELIVERY_VEHICLE_TIME:
      // modify only, DV should already exist if we are updating it's time
      return state.map((item) => {
        if (item.key === key) {
          return { ...item, key, time };
        }
        return item;
      });
    default:
      return state;
  }
}
