import { calculateDailyTotalForVehicle } from '../../helpers/order.helper';
import { depotsAPI } from '../../services/api/depots.apiService';
import { vendorsAPI } from '../../services/api/vendors.apiService';
import { vehiclesFS } from '../../services/models/vehicles.fs_model';
import { zonesAPI } from '../../services/api/zones.apiService';
import { ordersFS } from '../../services/models/orders.fs_model';
import { updateDrivingRoute } from './driverPosition.actions';
import * as types from './types';


export function storeVehicleInventory(data) {
  return {
    type: types.SET_DV_INVENTORY,
    data,
  };
}

export function storeDriverDeliveryZone(data) {
  return {
    type: types.SET_DRIVER_DELIVERY_ZONE,
    data,
  };
}

export function storeDriverDeliveryZones(data) {
  return {
    type: types.SET_DRIVER_DELIVERY_ZONES,
    data,
  };
}

export function storeDriverVehicle(data) {
  return {
    type: types.SET_DRIVER_VEHICLE,
    data,
  };
}

export function storeDriverVendor(data) {
  return {
    type: types.SET_DRIVER_VENDOR,
    data,
  };
}

export function storeDriverDepot(data) {
  return {
    type: types.SET_DRIVER_DEPOT,
    data,
  };
}

// ------------------------------------
// DATA FETCHING ACTIONS
// ------------------------------------

export function fetchVendorForDriver(vendorID) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.LOADING_DRIVER_VENDOR, loading: true });
      const vendor = await vendorsAPI.getVendorByID(vendorID);
      dispatch(storeDriverVendor(vendor));
      dispatch({ type: types.LOADING_DRIVER_VENDOR, loading: false });
      return vendor;
    } catch (e) {
      dispatch({ type: types.LOADING_DRIVER_VENDOR, loading: false });
    }
  };
}

export function fetchVendorDepotForDriver(depotID) {
  return async (dispatch) => {
    dispatch({ type: types.LOADING_DRIVER_DEPOT, loading: true });
    const depot = await depotsAPI.getByID(depotID);
    dispatch(storeDriverDepot(depot));
    dispatch(updateDrivingRoute());
    dispatch({ type: types.LOADING_DRIVER_DEPOT, loading: false });
    return depot;
  };
}

export function fetchDriverDeliveryZones(depotID) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.LOADING_DRIVER_DELIVERY_ZONES, loading: true });
      const zones = await zonesAPI.getZonesByParams({
        depotID,
        // includeCustomers: true,
        status: zonesAPI.STATUS.ACTIVE,
        limit: 1000
      });
      dispatch(storeDriverDeliveryZones(zones));
      dispatch({ type: types.LOADING_DRIVER_DELIVERY_ZONES, loading: false });
      return zones;
    } catch (error) {
      console.warn('fetchDriverDeliveryZones error', error);
    }
  };
}

export function fetchDriverDeliveryZone(deliveryZoneID) {
  return async (dispatch) => {
    try {
      if (deliveryZoneID) {
        dispatch({ type: types.LOADING_DRIVER_DELIVERY_ZONE, loading: true });
        const zone = await zonesAPI.getZoneByID(deliveryZoneID);
        dispatch(storeDriverDeliveryZone(zone));
        dispatch({ type: types.LOADING_DRIVER_DELIVERY_ZONE, loading: false });
        return zone;
      }
    } catch (error) {
      console.warn('fetchDriverDeliveryZone error', error);
    }
  };
}

export function startWatchingInventory(vehicleID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      vehiclesFS.watchInventory(vehicleID)
        .listen((inventory) => {
          dispatch(storeVehicleInventory(inventory));
          resolve(inventory);
        })
        .catch((error) => {
          console.warn('startWatchingInventory error', error);
          reject(error);
        });
    });
  };
}

export function watchDriverVehicle(vehicleID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      vehiclesFS.watchShallowDeliveryVehicleByID(vehicleID)
        .listen((vehicle) => {
          dispatch(storeDriverVehicle(vehicle));
          dispatch(fetchVendorForDriver(vehicle.vendorID));
          dispatch(fetchVendorDepotForDriver(vehicle.depotID));
          dispatch(fetchDriverDeliveryZone(vehicle.deliveryZoneID));
          dispatch(fetchDriverDeliveryZones(vehicle.depotID));
          resolve(vehicle);
        })
        .catch((error) => {
          console.warn('watchDriverVehicle error', error);
          reject(error);
        });
    });
  };
}
