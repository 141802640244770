import _get from 'lodash/get';

export const nearbyLoadingSelector = (state) => {
  return _get(state, 'customer.nearby.loading', null);
};

export const nearbyDeliverySelector = (state) => {
  return _get(state, 'customer.nearby.data.delivery', []);
};

export const nearbyPickupSelector = (state) => {
  return _get(state, 'customer.nearby.data.pickup', []);
};

export const nearbyShiftsSelector = (state) => {
  return _get(state, 'customer.nearby.data.shifts', []);
};

