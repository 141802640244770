import numeral from 'numeral';
import { roundFloat } from './math.helper';

export default function numberFormatter(value, format) {
  let fmt;
  switch (format) {
    case 'currency':
      fmt = '$0,000.00';
      break;
    case 'default':
      fmt = '0,000.00';
      break;
    case 'int':
      fmt = '0,000';
      break;
    default:
      break;
  }
  return numeral(value).format(fmt);
}

export function centsToDollars(cents) {
  return numberFormatter(cents / 100, 'currency');
}

export function toDollarString(dollars) {
  return numberFormatter(dollars, 'currency');
}

export function centsToDollarsFloat(cents) {
  return Number.parseFloat(roundFloat(cents / 100).toFixed(2));
}
