import firebase from '../../services/firebase.service';
import Listener from '../../helpers/Listener';
import FirestoreSanitizer from './FirestoreSanitizer';

class InventoryModel {
  constructor() {
    this.sanitize = new FirestoreSanitizer(this.COLLECTION_PROPS);
  }

  // ***
  // Module Constants
  // ***

  /**
   * Firestore collection name
   *
   * @type {string}
   */
  COLLECTION_NAME = 'inventory';

  /**
   * List of available properties for documents in this collections
   *
   * @type {string[]}
   */
  COLLECTION_PROPS = [
    'id',
    'name',
    'description',
    'requiredQuantity',
    'allocatedQuantity',
    'totalOnVehicle',
  ];

  /**
   * List of allowed sub-collections
   *
   * @type {string[]}
   */
  SUB_COLLECTIONS = [];

  subscriptions = {
    inventory: () => null,
  };

  // ***
  // Module Exported Functions
  // ***

  /**
   * Returns an array of Components objects for a given Delivery Vehicle
   *
   * @param vehicleRef - A firestore document reference of a deliveryVehicle
   * @returns {Promise<*>}
   */
  async get(vehicleRef) {
    const snapshot = await vehicleRef.collection(this.COLLECTION_NAME).get();
    return this.sanitize.collectionSnapshot(snapshot);
  }

  /**
   * Returns a Listener that will update with an array of Components objects for a given Delivery Vehicle
   *
   * @param vehicleRef - A firestore document reference of a deliveryVehicle
   * @returns {Listener<*>}
   */
  watch(vehicleRef) {
    return new Listener((onUpdate, onError) => {
      const componentsRef = vehicleRef.collection(this.COLLECTION_NAME);

      // Cancel any existing firestore watchers
      this.subscriptions.inventory();
      this.subscriptions.inventory = componentsRef.onSnapshot((snapshot) => {
        const components = this.sanitize.collectionSnapshot(snapshot);
        const inventory = components.map(item => {
          return {
            ...item,
            availableQuantity: item.totalOnVehicle - item.allocatedQuantity
          }
        })
        onUpdate(inventory);
      }, onError);
    });
  }

  /**
   * Increase or Decrease an inventory part's availableQuantity
   *
   * @param vehicleRef - A firestore document reference of a deliveryVehicle
   * @param partID - ID of the inventory component
   * @param adjustmentAmount - amount to add or remove (positive or negative integer)
   * @returns {Promise<*>}
   */
  async updateQuantity(vehicleRef, partID, adjustmentAmount) {
    const inventoryRef = vehicleRef.collection(this.COLLECTION_NAME);
    return firebase.firestore().runTransaction(async (transaction) => {
      const itemRef = inventoryRef.doc(partID);
      const doc = await transaction.get(itemRef);
      const currentQuantity = this.sanitize.document(doc).availableQuantity || 0;
      const availableQuantity = currentQuantity + adjustmentAmount;
      const updatedData = {
        availableQuantity,
      };
      return transaction.set(itemRef, this.sanitize.data(updatedData));
    });
  }

  /**
   * Set an inventory part's availableQuantity
   *
   * @param vehicleRef - A firestore document reference of a deliveryVehicle
   * @param partID - ID of the inventory component
   * @param availableQuantity - amount to set (positive integer)
   * @returns {Promise<*>}
   */
  async setQuantity(vehicleRef, partID, availableQuantity) {
    const inventoryRef = vehicleRef.collection(this.COLLECTION_NAME);
    return firebase.firestore().runTransaction(async (transaction) => {
      const itemRef = inventoryRef.doc(partID);
      const updatedData = {
        availableQuantity: parseInt(availableQuantity, 10),
      };
      return transaction.set(itemRef, this.sanitize.data(updatedData));
    });
  }
}

export const Inventory = new InventoryModel();
export default Inventory;
