export default class Listener {
  constructor(setupCallbacks) {
    setTimeout(() => {
      const onUpdate = (...args) => {
        if (typeof this.successListener === 'function') {
          this.successListener(...args);
        }
      };
      const onError = (error) => {
        if (typeof this.errorListener === 'function') {
          this.errorListener(error);
        }
      };
      setupCallbacks(onUpdate, onError);
    });
  }

  listen(successListener) {
    this.successListener = successListener;
    return this;
  }

  catch(errorListener) {
    this.errorListener = errorListener;
    return this;
  }
}
