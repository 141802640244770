import _get from 'lodash/get';
import { combineReducers } from 'redux';
import * as types from '../actions/types';

// ------------------------------------
// SELECTORS
// ------------------------------------
export const depotDailyOrdersSelector = (state) => {
  return _get(state, 'depotApp.dailyOrders.data', []);
};


// ------------------------------------
// ACTIONS
// ------------------------------------
export function storeDepotDailyOrders(data) {
  return {
    type: types.SET_DEPOT_DAILY_ORDERS,
    data,
  };
}


// ------------------------------------
// REDUCER
// ------------------------------------
const initialState = {
  loading: false,
  data: [],
};

function loading(state = initialState.loading, action) {
  switch (action.type) {
    case types.LOADING_DEPOT_DAILY_ORDERS:
      return action.loading;
    default:
      return state;
  }
}

function data(state = initialState.data, action) {
  switch (action.type) {
    case types.SET_DEPOT_DAILY_ORDERS:
      return action.data;
    default:
      return state;
  }
}


export default combineReducers({
  loading,
  data,
});
