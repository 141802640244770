import _get from 'lodash/get';

export const connectedVehicleLoadingSelector = (state) => {
  return _get(state, 'customer.connectedVehicle.loading', null);
};

export const connectedVehicleSelector = (state) => {
  return _get(state, 'customer.connectedVehicle.data', null);
};

