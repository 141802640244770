import React from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

AnimatedScreen.propTypes = {
  style: PropTypes.object,
};

AnimatedScreen.defaultProps = {
  style: {},
};

export function AnimatedScreen(props) {
  const combinedStyles = {
    ...styles.fullHeightAndWidth,
    ...props.style,
  };
  return (
    <animated.div style={combinedStyles}>
      {props.children}
    </animated.div>
  );
}

const styles = {
  fullHeightAndWidth: {
    position: 'fixed',
    zIndex: 9999,
    minHeight: '100vh',
    width: '100%',
  },
};
