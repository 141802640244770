import CircularProgress from 'material-ui/CircularProgress';
import React from 'react';
import { FullHeightContainer } from '../../atoms/containers/FullHeightContainer';

export function GenericSplash() {
  return (
    <FullHeightContainer
      background="#ffffff"
      display="flex"
      justify="center"
      align="center"
      overflow="hidden"
    >
      <div style={styles.container}>
        <CircularProgress />
      </div>
    </FullHeightContainer>
  );
}

const styles = {
  container: {
    margin: 0,
    marginTop: '-10vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxWidth: 400,
  },
  image: {
    width: '100%',
    marginBottom: 16,
  },
};
