export const SET_INITIALIZED = "SET_INITIALIZED";
export const APP_ERROR = "APP_ERROR";

/**
 * Delivery Vehicle Position Actions
 */
export const ADD_DELIVERY_VEHICLE_POSITION = "ADD_DELIVERY_VEHICLE_POSITION";
export const UPDATE_DELIVERY_VEHICLE_TIME = "UPDATE_DELIVERY_VEHICLE_TIME";

export const CONFIG_CHANGED = "CONFIG_CHANGED";
export const SET_CART = "SET_CART";
export const SET_CART_BILL_DETAILS = "SET_CART_BILL_DETAILS";
export const SET_CART_IS_LOADING = "SET_CART_IS_LOADING";
export const SET_CART_BILL_DETAILS_IS_LOADING =
  "SET_CART_BILL_DETAILS_IS_LOADING";
export const TOGGLE_MENU_CATEGORY = "TOGGLE_MENU_CATEGORY";
export const AUTH_LOGGED_IN = "AUTH_LOGGED_IN";
export const CLEAR_CART = "CLEAR_CART";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_CHANGE_ITEM_QUANTITY = "CART_CHANGE_ITEM_QUANTITY";
export const SET_ORDERS = "SET_ORDERS";
export const LOADING_DV_INVENTORY = "LOADING_DV_INVENTORY";
export const SET_DV_INVENTORY = "SET_DV_INVENTORY";
export const SET_ESTIMATED_DELIVERY_TIME = "SET_ESTIMATED_DELIVERY_TIME";

/**
 * Nearby Actions
 */
export const LOADING_NEARBY = "LOADING_NEARBY";
export const SET_NEARBY = "SET_NEARBY";

/**
 * User Actions
 */
export const LOADING_AUTH_USER = "LOADING_AUTH_USER";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const LOADING_USER_PROFILE = "LOADING_USER_PROFILE";

/**
 * User Location Actions
 */
export const LOADING_DELIVERY_ADDRESS = "LOADING_DELIVERY_ADDRESS";
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS";
export const SET_LOCATING = "SET_LOCATING";
export const SET_CUSTOMER_POSITION = "SET_CUSTOMER_POSITION";

/**
 * Customer "Connected" Vehicle Actions
 */
export const LOADING_CONNECTED_VEHICLE = "LOADING_CONNECTED_VEHICLE";
export const SET_CONNECTED_VEHICLE = "SET_CONNECTED_VEHICLE";
export const SET_CONNECTED_VEHICLE_INVENTORY =
  "SET_CONNECTED_VEHICLE_INVENTORY";

/**
 * Customer "Connected" Promo Actions
 */
export const LOADING_CONNECTED_PROMO = "LOADING_CONNECTED_PROMO";
export const SET_CONNECTED_PROMO = "SET_CONNECTED_PROMO";

/**
 * Customer "Connected" MenuID Actions
 */
export const SET_CONNECTED_MENU_ID = "SET_CONNECTED_MENU_ID";

/**
 * Customer "Connected" Shift Actions
 */
export const SET_CONNECTED_SHIFT = "SET_CONNECTED_SHIFT";

/**
 * Customer "Connected" Zone Actions
 */
export const SET_CONNECTED_ZONE = "SET_CONNECTED_ZONE";
export const SET_CONNECTED_POPUP_DISTANCE = "SET_CONNECTED_POPUP_DISTANCE";

/**
 * Customer "Connected" Vendor Actions
 */
export const LOADING_CONNECTED_VENDOR = "LOADING_CONNECTED_VENDOR";
export const SET_CONNECTED_VENDOR = "SET_CONNECTED_VENDOR";

/**
 * Customer "Order Confirmation" Actions
 */
export const SET_LAST_ORDER = "SET_LAST_ORDER";
export const SET_LAST_ORDER_VEHICLE = "SET_LAST_ORDER_VEHICLE";
export const SET_LAST_ORDER_ZONE = "SET_LAST_ORDER_ZONE";

/**
 * Driver Actions
 */
export const LOADING_DRIVER_POSITION = "LOADING_DRIVER_POSITION";
export const SET_DRIVER_POSITION = "SET_DRIVER_POSITION";

export const LOADING_DRIVER_VEHICLE = "LOADING_DRIVER_VEHICLE";
export const SET_DRIVER_VEHICLE = "SET_DRIVER_VEHICLE";

export const LOADING_DRIVER_VENDOR = "LOADING_DRIVER_VENDOR";
export const SET_DRIVER_VENDOR = "SET_DRIVER_VENDOR";

export const LOADING_DRIVER_DEPOT = "LOADING_DRIVER_DEPOT";
export const SET_DRIVER_DEPOT = "SET_DRIVER_DEPOT";

export const LOADING_DRIVER_DELIVERY_ZONE = "LOADING_DRIVER_DELIVERY_ZONE";
export const SET_DRIVER_DELIVERY_ZONE = "SET_DRIVER_DELIVERY_ZONE";

export const LOADING_DRIVER_DELIVERY_ZONES = "LOADING_DRIVER_DELIVERY_ZONES";
export const SET_DRIVER_DELIVERY_ZONES = "SET_DRIVER_DELIVERY_ZONES";

export const LOADING_VENDOR_DELIVERY_ZONES = "LOADING_VENDOR_DELIVERY_ZONES";

export const LOADING_DRIVER_DIRECTIONS = "LOADING_DRIVER_DIRECTIONS";
export const SET_DRIVER_DIRECTIONS = "SET_DRIVER_DIRECTIONS";

export const LOADING_DRIVER_ACTIVE_ORDERS = "LOADING_DRIVER_ACTIVE_ORDERS";
export const SET_DRIVER_ACTIVE_ORDERS = "SET_DRIVER_ACTIVE_ORDERS";

export const LOADING_RELEVANT_SHIFT = "LOADING_RELEVANT_SHIFT";
export const SET_RELEVANT_SHIFT = "SET_RELEVANT_SHIFT";
export const SET_SHIFT_PREORDERS = "SET_SHIFT_PREORDERS";
export const SET_SHIFT_SENT_PREORDER_REMINDERS =
  "SET_SHIFT_PREORDERS_SENT_REMINDERS";
export const SET_SHIFT_REMINDER_ELIGIBLE_PREORDERS =
  "SET_SHIFT_REMINDER_ELIGIBLE_PREORDERS";

export const LOADING_DRIVER_DAILY_TOTAL = "LOADING_DRIVER_DAILY_TOTAL";
export const SET_DRIVER_DAILY_TOTAL = "SET_DRIVER_DAILY_TOTAL";

export const LOADING_DRIVER_CLOSED_ORDERS = "LOADING_DRIVER_CLOSED_ORDERS";
export const SET_DRIVER_CLOSED_ORDERS = "SET_DRIVER_CLOSED_ORDERS";

export const LOADING_DRIVER_VEHICLE_POSITION =
  "LOADING_DRIVER_VEHICLE_POSITION";
export const SET_DRIVER_VEHICLE_POSITION = "SET_DRIVER_VEHICLE_POSITION";

export const LOADING_DRIVER_MODIFIED_ORDER = "LOADING_DRIVER_MODIFIED_ORDER";
export const SET_DRIVER_MODIFIED_ORDER = "SET_DRIVER_MODIFIED_ORDER";
export const MODIFIED_ORDER_UPDATE_ITEM = "MODIFIED_ORDER_UPDATE_ITEM";
export const MODIFIED_ORDER_ADD_ITEM = "MODIFIED_ORDER_ADD_ITEM";
export const MODIFIED_ORDER_REMOVE_ITEM = "MODIFIED_ORDER_REMOVE_ITEM";
export const CLEAR_MODIFIED_ORDER = "CLEAR_MODIFIED_ORDER";

/**
 * Driver Flash Notifications Actions
 */
export const LOADING_FLASH_CUSTOMERS = "LOADING_FLASH_CUSTOMERS";
export const SET_FLASH_CUSTOMERS = "SET_FLASH_CUSTOMERS";

export const LOADING_FLASH_MESSAGES = "LOADING_FLASH_MESSAGES";
export const SET_FLASH_MESSAGES = "SET_FLASH_MESSAGES";

export const LOADING_FLASH_DELIVERED = "LOADING_FLASH_DELIVERED";
export const SET_FLASH_DELIVERED = "SET_FLASH_DELIVERED";

export const SENDING_FLASH_NOTIFICATIONS = "SENDING_FLASH_NOTIFICATIONS";
export const SET_FLASH_NOTIFICATION_ERROR = "SET_FLASH_NOTIFICATION_ERROR";

/**
 * Inventory
 */
export const LOADING_INVENTORY_MENUS = "LOADING_INVENTORY_MENUS";
export const SET_INVENTORY_MENUS = "SET_INVENTORY_MENUS";

export const LOADING_INVENTORY_COMPONENTS = "LOADING_INVENTORY_COMPONENTS";
export const SET_INVENTORY_COMPONENTS = "SET_INVENTORY_COMPONENTS";

export const LOADING_INVENTORY_MENU_ITEMS = "LOADING_INVENTORY_MENU_ITEMS";
export const SET_INVENTORY_MENU_ITEMS = "SET_INVENTORY_MENU_ITEMS";

export const LOADING_ACTIVE_MENU_ITEMS = "LOADING_ACTIVE_MENU_ITEMS";
export const SET_ACTIVE_MENU_ITEMS = "SET_ACTIVE_MENU_ITEMS";

export const LOADING_ACTIVE_MENU_COMPONENTS = "LOADING_ACTIVE_MENU_COMPONENTS";
export const SET_ACTIVE_MENU_COMPONENTS = "SET_ACTIVE_MENU_COMPONENTS";

/**
 * Vendor Data
 */
export const LOADING_VENDOR_DAILY_ORDERS = "LOADING_VENDOR_DAILY_ORDERS";
export const SET_VENDOR_DAILY_ORDERS = "SET_VENDOR_DAILY_ORDERS";

/**
 * Voice Call Data
 */
export const LOADING_VOICE_CALL = "LOADING_VOICE_CALL";
export const SET_VOICE_CALL_DEVICE = "SET_VOICE_CALL_DEVICE";
export const SET_VOICE_CALL_CONNECTION = "SET_VOICE_CALL_CONNECTION";
export const RESET_VOICE_CALL_LOG = "RESET_VOICE_CALL_LOG";
export const ADD_VOICE_CALL_LOG = "ADD_VOICE_CALL_LOG";
export const SET_VOICE_CALL_ERROR = "SET_VOICE_CALL_ERROR";
export const SET_VOICE_CALL_CONNECTED = "SET_VOICE_CALL_CONNECTED";
export const SET_VOICE_CALL_IS_MUTED = "SET_VOICE_CALL_IS_MUTED";
export const SET_VOICE_CALL_VOLUME = "SET_VOICE_CALL_VOLUME";

// ------------------------------------
// DEPOT APP
// ------------------------------------
export const LOADING_DEPOT_APP_DEPOT = "LOADING_DEPOT_APP_DEPOT";
export const SET_DEPOT_APP_DEPOT = "SET_DEPOT_APP_DEPOT";
export const LOADING_DEPOT_APP_DEPOTS = "LOADING_DEPOT_APP_DEPOTS";
export const SET_DEPOT_APP_DEPOTS = "SET_DEPOT_APP_DEPOTS";
export const LOADING_DEPOT_APP_VENDOR = "LOADING_DEPOT_APP_VENDOR";
export const SET_DEPOT_APP_VENDOR = "SET_DEPOT_APP_VENDOR";
export const LOADING_DEPOT_DAILY_ORDERS = "LOADING_DEPOT_DAILY_ORDERS";
export const SET_DEPOT_DAILY_ORDERS = "SET_DEPOT_DAILY_ORDERS";

// ------------------------------------
// VENDOR APP
// ------------------------------------
export const LOADING_VENDOR_APP_VENDOR = "LOADING_VENDOR_APP_VENDOR";
export const SET_VENDOR_APP_VENDOR = "SET_VENDOR_APP_VENDOR";

export const SET_FIRST_REDIRECT = "SET_FIRST_REDIRECT";
