import { fleatAPI } from '../api.service';

class VendorvehiclesFS {

  /**
   * This will fetch a list of Vehicles (for VENDOR users)
   *
   * @returns {Promise<Array>} - list of vehicle objects
   */
  async getAllVehicles(vendorID) {
    return fleatAPI.callAPI({
      url: `/vehicles?vendorID=${vendorID}`,
    });
  }

  async getAllDepotVehicles(depotID) {
    return fleatAPI.callAPI({
      url: `/vehicles?depotID=${depotID}`,
    });
  }

  updateVehiclePosition(vehicleID, position) {
    return fleatAPI.callAPI({
      url: `vehicles/${vehicleID}/position`,
      method: 'PATCH',
      data: position,
    });
  }

  /**
   * Will refresh the delivery queue times for delivery vehicle
   *
   * @param vehicleID
   * @returns {*}
   */
  updateDeliveryQueueForVehicle(vehicleID) {
    return fleatAPI.callAPI({
      url: `vehicles/${vehicleID}/queue`,
      method: 'PATCH',
    });
  }
}

export const vehiclesAPI = new VendorvehiclesFS();
