import { combineReducers } from "redux";
import * as types from "../../actions/types";

const initialStateData = false;

function data(state = initialStateData, action) {
  switch (action.type) {
    case types.SET_FIRST_REDIRECT:
      return action.data;
    default:
      return state;
  }
}

export default data;
